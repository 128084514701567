.dispute-decisions-container, .dispute-decisions-container-mobile {
  .decision-comment {
    background: linear-gradient(179deg, rgb(255 214 214) 0%, rgb(237, 237, 237) 82%);
    padding: 12px 10px;
    border-radius: 3px;
    border: 1px solid #bb3c3c;
    width: 600px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;

    .icon {
      font-size: 26px;
      color: #bb3c3c;
      margin-top: 9px;
    }
    .content {
      margin-left: 25px;

      .date {
        font-size: 15px;
      }
      .comment {
        margin-top: 5px;
        font-weight: bold;
        font-size: 15px;
      }
    }
  }

  .decision-invalidate-ext {
    background: linear-gradient(179deg, rgb(255 214 214) 0%, rgb(237, 237, 237) 82%);
    padding: 12px 10px;
    border-radius: 3px;
    border: 1px solid #bb3c3c;
    width: 600px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;

    .icon {
      font-size: 26px;
      color: #bb3c3c;
      margin-top: 9px;
    }
    .content {
      margin-left: 25px;

      .date {
        font-size: 15px;
      }
      .comment {
        margin-top: 5px;
        font-weight: bold;
        font-size: 15px;
      }
    }
  }

  &-mobile {
    margin-top: 10px;

    .decision-comment {
      margin-top: 0px;
      width: 94%;
      .icon {
        font-size: 22px;
      }
      .content {
        margin-left: 15px;
      }
    }

    .decision-invalidate-ext {
      margin-top: 0px;
      width: 94%;
      .icon {
        font-size: 22px;
      }
      .content {
        margin-left: 15px;
      }
    }

  }
}