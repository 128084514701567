.checkbox {
  display: inline-flex;
  margin-top: 5px;
  padding: 7px 14px 5px 9px;
  background-color: #ededed;
  border-radius: 6px;

  .cb-icon {
    cursor: pointer;
    font-size: 20px;
    margin-right: 10px;
    margin-top: 2px;
  }

  .label {
    cursor: pointer;
    font-size: 17px;
  }
}