.disputed {
  background: linear-gradient(180deg, rgb(255 142 12 / 14%) 0%, rgb(255 21 21 / 0%) 50%);
  padding: 0px 16px;
  padding-top: 12px;
  margin-bottom: 10px !important;
  border-radius: 13px;
}

.border-internal {
  border: 1px solid rgb(229 219 155) !important;
}

.border-external {
  border: 1px solid rgb(233 175 92) !important;
}

.border-damage {
  border: 1px solid rgb(233 117 117) !important;
}

.border-general {
  border: 1px solid rgb(117 155 233) !important;
}

.record-extension-container, .record-extension-container-mobile{
  border: 1px solid #e7e7e7;
  width: 760px;
  margin: 0 auto;
  margin-bottom: 70px;
  padding: 50px 5px;
  border-radius: 30px;

  .invalidated-container {
    .info {
      color: red;
    }
  }

  .record-content {
    .disputed-tag {
      background-color: #ffb197;
      padding: 5px 12px;
      width: fit-content;
      margin-bottom: 87px !important;
      margin-top: 25px !important;
      border-radius: 3px;
      margin: 0 auto;

      .icon {
        margin-right: 9px;
        font-size: 20px;
        margin-bottom: -3px;
        color: #912b00;
      }

    }

    .l1 {
      .ext-internal {
        background: linear-gradient(63deg, rgb(229 219 155) 0%, rgb(207 199 56) 100%);
      }

      .ext-external {
        background: linear-gradient(63deg, rgb(233 175 92) 0%, rgb(237 155 38) 100%);
      }

      .ext-damage {
        background: linear-gradient(63deg, rgb(255 147 147) 0%, rgb(233 117 117) 100%);
      }

      .ext-general {
        background: linear-gradient(63deg, rgb(147, 201, 255) 0%, rgb(117 155 233) 100%)
      }

      .record-type {
        margin-bottom: 0px !important;
      }

      .added-on-container {
        display: inline-flex;
        padding: 7px 15px 2px 18px;
        border-left: none !important;
        border-radius: 5px;
        margin-left: -4px;

        .label {
          margin-right: 12px;
        }

        .data {
          display: block;
          margin-left: -3px;
          border-radius: 3px;
          border-left: none !important;

          .cal-icon {
            margin-right: 7px;
            margin-bottom: -2px;
            color: #484848;
          }

        }
      }

      .data-internal {
        border: 1px solid #cdc95d;
      }

      .data-external {
        border: 1px solid #daad62;
      }

      .data-damage {
        border: 1px solid #e28f8d;
      }

      .data-general {
        border: 1px solid #759BE9FF;
      }

    }

    .l2 {
      margin-top: 35px;

      .display-name-label {
        display: contents;
        width: fit-content;

      }

      .display-name {
        font-family: monospace;
        background-color: #e1e1e1;
        padding: 5px 13px 5px 12px;
        border-radius: 5px;
        width: fit-content;
        margin: 0 auto !important;
        margin-top: 5px !important;
      }
    }

    .l3 {
      margin-top: 25px;

      .record-creation-date {
        display: inline-block;
        width: fit-content;

        .data {
          margin-top: 7px;
        }

      }
    }

    .l4 {
      margin-top: 40px;

      .descr-container {
        width: 700px;
        margin: 0 auto;

        .descr-label {
          font-size: 17px;
          color: #747474;
          font-weight: bold;
        }

        .descr-val {
          margin-top: 10px;

          .red-quotes {
            color: #cd2328;
            font-size: 17px;
            font-weight: bold;
          }

          #descr {
            margin-bottom: 11px;
            margin-left: 3px;
            margin-right: 3px;
          }
        }
      }
    }

    .trx-receipt-container {
      display: inline-block;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .img-presenter {
      margin-top: 10px;
    }

    .change-type {
      margin-top: 17px;
      margin-bottom: 8px;

      .type-span {
        border: 1px solid grey;
        padding: 3px 8px;
        font-size: 15px;
        border-radius: 3px;
        cursor: default;
      }
    }

    .change-date {
      .info-tooltip-wrapper .info-tooltip-container {
        margin-top: -25px;
        margin-right: -22px;
      }
    }


  }

  .existing-dispute-notes {
    margin-top: 20px;

    h3 {
      font-size: 16px;
    }

    .info {
      .icon {
        margin-right: 8px;
        margin-bottom: -2px;
      }

      font-size: 15px;
      margin-bottom: 6px;
      color: grey;
    }

    .hide-link {
      margin-top: 2px;
      margin-bottom: 15px;
      margin-left: -3px;
      cursor: pointer;
      font-size: 15px;
      color: #93200f;
      padding-left: 3px;
    }

    .counter {
      font-size: 15px;
      color: #93200f;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .single {
      background: linear-gradient(62deg, rgb(245 236 219) 0%, rgb(255 242 225) 100%);
      width: fit-content;
      padding: 11px 12px;
      padding-right: 16px;
      margin-bottom: 20px;
      border-radius: 3px;

      .date {
        font-size: 17px;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .originator {
        margin-top: 4px;
        font-size: 17px;
      }

      .note {
        font-size: 17px;
        margin-top: 25px;
        font-weight: bold;
        margin-bottom: 10px;
      }

    }
  }

  .dispute-container {
    margin-top: 10px;
    padding-bottom: 20px;
    display: inline-block;

    .existing-dispute-notes {
      h3 {
        font-size: 17px;
      }

      .info {
        font-size: 17px;
        margin-bottom: 20px;

        .icon {
          margin-right: 8px;
          margin-bottom: -2px;
        }
      }

      .hide-link {
        font-size: 17px;
        margin-top: -4px;

        &:hover {
          text-decoration: underline;
        }
      }

      .counter {
        font-size: 17px;
        margin-bottom: 20px;

        .text-icon {
          margin-bottom: -3px !important;
        }
      }

      .single {
        width: 92% !important;

        .originator {
          margin-top: 8px !important;
          display: inline-flex;

          .data {
            margin-top: -5px !important;
            margin-left: 15px !important;
          }
        }
      }
    }

    .add-dispute-container .add-link {
      font-size: 15px !important;
    }

    .add-dispute-container {
      margin-top: 15px;

      .add-link {
        margin-left: -3px;
        cursor: pointer;
        font-size: 17px !important;
        background: #fbfbfb;
        padding: 4px 12px;
        border-radius: 10px;
        border: 1px solid #c8c8c8;

        &:hover {
          background: #f7f7f7;
          border-color: #b7b7b7;
        }
      }

      .dispute-form {
        padding-bottom: 30px;
        width: 100%;

        .dispute-descr {
          width: 94% !important;
          height: 80px !important;
        }

        #button-container {
          width: 100%;

          #submit-button {
            margin-top: -2px;
            height: 43px !important;
            width: 170px !important;
          }
          #cancel-button {
            margin-top: -2px;
            margin-left: 20px;
            height: 43px !important;
            width: 170px !important;
          }
          #spinner {
            margin-top: 3px;
            margin-left: 20px;
            margin-right: -47px;
            height: 25px;
          }
        }

        #submit-dispute-hint-container {
          margin-top: 15px;
          width: 399px;
          text-align: left;
        }

      }

    }
  }

  &-mobile {
    width: 100%;
    margin-left: -4px;

    .record-content {
      .inline {
        display: inline-flex;
      }

      .l1 {
        .record-type {
          height: 28px;
          margin-top: -1px;
          padding-top: 6px !important;
          padding-bottom: 2px !important;
          font-size: 17px !important;
        }

        .added-on-container {
          display: inline-flex;
          height: 22px;
          margin-top: -1px;
          margin-left: -5px;
          padding: 7px 15px 5px 10px;

          .change-date {
            display: inline-flex;
          }

          .label {
            margin-right: 8px;
          }

          .data {
            font-size: 17px !important;
            margin-left: 0px !important;
            margin-top: 0px;
          }
        }

        .cal-icon {
          margin-right: 9px !important;
          margin-bottom: -2px !important;
        }
      }

      .l2 {
        margin-top: 35px;

        .originator {
          margin-top: -5px !important;
          font-size: 16px !important;
          margin-left: 1px;

          .data {
            margin-top: -4px !important;
            margin-left: 12px !important;
            font-size: 16px !important;
          }
        }
      }

      .l3 {
        margin-top: 35px;

        .record-creation-date {
          .label {
            font-size: 18px;
          }

          .data {
            padding-top: 8px !important;
          }
        }
      }

      .l4 {
        .descr-container {
          width: fit-content;

          .descr-label {
            margin-top: 15px;
            font-size: 16px;
          }

          .descr-val {
            margin-top: 9px;
            font-size: 16px;
            width: 100%;
            justify-content: center;
          }

          .descr {
            font-size: 18px;
          }
        }
      }

      .dispute-container {
        .add-link {
          padding: 7px 15px;
        }
      }

    }

  }

}