#create-branch-container, #create-branch-container-mobile {
  margin-top: 30px;
  margin-bottom: 100px;
  padding-left: 4px;

  .descr {
    margin-bottom: 10px;
  }

  #left {
    float: left;
    width: 590px;

    #branch-hints {
      margin-top: 30px;
    }
  }

  #right {
    float: right;
  }

  #submit-container {
    #submit-button {
      margin-top: 15px;
      width: 422px;
    }
    #spinner {
      width: 20px !important;
      margin-left: 15px !important;
      margin-top: 22px !important;
    }

    #hint-container {
      margin-top: 13px;
    }
  }

  .trx-receipt-container {
    margin: 0 auto;
  }

  #clearer {
    clear: both;
  }

  &-mobile {
    display: block;

    #left {
      float: none;
      width: 100% !important;

      .descr {
        font-size: 14px;
      }

      #submit-container {
        margin-bottom: 30px;

        #submit-button {
          height: auto;
        }

        #spinner {
          width: 20px;
          margin-left: 155px;
          margin-top: 15px;
        }
      }

    }

    #right {
      float: none;
    }
  }

}