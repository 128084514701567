#imprint-container, #imprint-container-mobile {
  padding-bottom: 100px !important;

  #intro-descr {
    margin-bottom: 30px;
  }

  .paragraph {
    width: 960px;
  }

  h2 {
    margin-top: 40px;
  }

  h3 {
    margin-top: 30px;
  }

  #email-hidden {
    background-color: #e0e0e0;
    padding: 3px 12px;
    border-radius: 6px;
    cursor: pointer;
    width: fit-content;
  }

  &-mobile {
    a {
      font-size: 17px;
      line-break: anywhere;
    }

    p {
      width: 98% !important;
    }

    #email-hidden {
      width: 95% !important;
    }
  }
}