#headline-package, #headline-package-mobile {
  position: relative;
  padding-bottom: 60px;
  background: linear-gradient(90deg, rgb(98, 98, 98) 0%, rgb(119, 119, 119) 28%, rgb(147, 147, 147) 52%, rgb(145, 145, 145) 72%, rgba(0, 0, 0, 1) 100%);
  overflow: hidden;
  height: 650px;


  #background-video {
    position: absolute;
    z-index: 0;
    clip-path: inset(-1px -1px);
    width: 101%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0%;
  }

  #content {
    background: linear-gradient(180deg, #73c5636e, #a2ff983d 3%, #535646a1 26%,
            #8075614d 59%, #ffffff45 92%, #fff0);
    //z-index: 1;
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding-top: 60px;
    padding-bottom: 30px;

    .red {
      color: white;
    }

    #headline-container {
      position: relative;
      text-align: center;
      margin: 0 auto;
      padding: 10px 40px;
      padding-top: 20px;

      #level-up {
        word-spacing: 2px;
        margin-bottom: 25px !important;
        font-size: 52px;
        /* animation: colorFade 5s infinite; */
      }

      .headline {
        word-spacing: 4px;
        font-family: "BS", "Arial", sans-serif;
        transform: scaleY(0.9);
        font-size: 50px;
        color: #ffffff;
        line-height: 30px;
        text-shadow: 0 0 12px rgb(0 0 0 / 84%);
      }

      @keyframes colorFade {
        0% {
          color: white;
        }
        30% {
          color: #f14a37;
        }
        55% {
          color: #eb8d49;
        }
        100% {
          color: white;
        }
      }
    }

    #platform {
      position: relative;
      margin: 0 auto;
      margin-top: 30px !important;
      color: white;
      text-align: center;
      font-size: 21px;
      line-height: 28px;
      width: 700px;
      text-shadow: 0 1px 10px rgb(0 0 0 / 90%);
    }

    #instant-benefit {
      position: relative;
      color: white;
      text-align: center;
      font-size: 21px;
      margin: 0 auto;
      margin-top: 30px !important;
      line-height: 28px;
      width: 700px;
      text-shadow: 0 1px 10px rgb(0 0 0 / 90%);
    }

    #order-button-container {
      text-align: center;

      #order-button {
        background: linear-gradient(178deg, rgb(207, 94, 73) 0%, rgb(187 68 46) 100%);
        border: 2px solid #c5c5c5;
        padding-left: 20px;
        padding-right: 20px;
        height: 50px;
        margin-top: 40px;
        font-size: 20px;

        &:hover {
          background: linear-gradient(178deg, rgb(196, 87, 67) 0%, rgb(171 54 33) 100%);
          box-shadow: unset;
        }
      }
    }

    #social-media-container {
      text-align: center;
      margin-top: 60px;

      #t {
        background: linear-gradient(157deg, rgb(236 214 239 / 50%) 0%, rgba(255, 172, 186, 0.48) 100%);
        width: fit-content;
        margin: 0 auto;
        padding: 1px 20px;
        border-radius: 14px;
        font-size: 17px;
      }

      .sm-name {
        cursor: pointer;
        background: linear-gradient(202deg, rgb(255 255 255 / 26%) 0%, rgb(237 237 237 / 68%) 100%);
        width: -moz-fit-content;
        width: fit-content;
        padding: 0px 14px;
        border-radius: 14px;
        font-size: 16px;
        margin-top: 2px;

        &:hover {
          background: linear-gradient(202deg, rgba(255, 255, 255, 0.45) 0%, rgba(237, 237, 237, 0.74) 100%);
        }
      }

      #links {
        margin-top: 25px;
      }

      #ig-container {
        cursor: pointer;
        margin-right: 15px;

        #ig-logo {
          height: 37px;
        }
      }

      #yt-container {
        cursor: pointer;
        #yt-logo {
          height: 37px;
        }
      }
    }

  }

  &-mobile {
    display: block;
    text-align: center;
    height: 700px;

    #background-video {
      width: 110%;
      height: 100%;
      left: -3%;
    }

    #content {
      z-index: 0;
      width: 100%;
      margin: 0 auto;
      padding-top: 35px;

      #headline-container {
        text-align: center;

        .inline {
          display: block;
        }

        #level-up {
          margin-bottom: 5px !important;
          font-size: 40px;
        }

        .headline {
          font-family: "BS", "Arial", sans-serif;
          transform: scaleY(0.9);
          font-size: 38px;
          letter-spacing: -2px;
          line-height: 38px;
          text-align: center;
          text-shadow: 0 0 12px rgba(0, 0, 0, 0.35) !important;
        }

      }

      #platform {
        margin: 0 auto;
        font-size: 20px;
        margin-top: 15px !important;
        line-height: 25px;
        width: 85%;
      }

      #instant-benefit {
        font-size: 20px;
        margin-top: 25px !important;
        line-height: 26px;
        width: 85%;
        margin: 0 auto;
      }

      #order-button-container {
        text-align: center;

        #order-button {
          height: 54px !important;
          margin-top: 40px;
          font-size: 20px;
          width: 90% !important;
        }
      }

      #social-media-container {
        margin-top: 45px;

        #t {
          font-size: 17px;
        }

        .sm-name {
          margin-top: 2px;
        }

        #links {
          margin-top: 25px;
        }

        #ig-container {
          margin-right: 15px;

          #ig-logo {
            height: 37px;
          }
        }

        #yt-container {
          cursor: pointer;
          #yt-logo {
            height: 37px;
          }
        }
      }

    }
  }
}

