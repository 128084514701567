.text-area-container {
    display: grid;
    margin-top: 15px;
    margin-bottom: 15px;

    .label-container {
        margin-top: 8px;
        display: inline-flex;
    }

    .char-limit {
        margin-top: 1px;
        font-size: 12px;
        margin-left: 7px;
        color: #9c5f02;
        margin-bottom: 0px;
    }

}


.text-area {
    resize: none;
    font-family: Arial;
    font-size: 18px;
    margin-top: 8px;
    margin-bottom: 6px;
    border: 1px solid #949494 !important;
    background: linear-gradient(155deg, rgb(245 245 245) 0%, rgb(239, 246, 247) 100%);
    padding: 13px 11px 10px 10px;
    outline: none;
    border-radius: 10px;

    &:hover {
        border: 1px solid #ad3d2c !important;
        background-color: #e8e8e8de;
    }

    &:focus {
        background-color: #e8e8e8de;
        border: 1px solid #ad3d2c !important;
    }

}