.due-branch-merger-container, .due-branch-merger-container-mobile {
  margin-top: 30px;
  margin-bottom: 30px;

  #container-h1 {
    font-size: 18px;
  }

  #due-branch-descr {
    font-size: 15px;
  }

  #due-branch-merge-submit-container {
    #vtr-calc {
    }

    #submit-button {
      width: 393px;
    }
  }

  #reload-info {
    font-size: 15px;
    margin-top: 20px;
    padding-left: 5px;
  }

  &-mobile {
    margin-top: 10px !important;
    #due-branch-merge-submit-container {
      #submit-button {
        width: 100%;
      }
    }
  }
}