.content-container {
  margin-top: 35px !important;
}

.search-container, .search-container-mobile {
  #compact-search-form-container {
    width: 1300px;
    margin: 0 auto;
    margin-top: 50px !important;

    #compact-search-form {
      width: 500px;
      margin-top: 40px;
      padding: 1px 2px;
      margin-bottom: 10px;

      h1 {
        margin-top: 0px;
        margin-bottom: 40px !important;
      }

      #label {
        margin-bottom: -1px;
        font-size: 17px;
        color: #000000;
        background: linear-gradient(137deg, rgb(235, 235, 235) 0%, rgb(241, 241, 241) 81%);
        width: -moz-fit-content;
        width: fit-content;
        padding: 4px 12px;
        border-radius: 8px;
      }

      #serial-nr {
        #serial-nr-input, #serial-nr-input-input {
          margin-top: 0px;
          width: 330px !important;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }

        #submit-button {
          margin-top: 0px;
          height: 45px;
          margin-left: -6px;
          width: 50px;
        }
      }

      #spinner {
        height: 20px;
        margin-left: -80px;
        margin-top: 28px;
      }
    }

    #content-spinner {
      margin-top: 20px;
      height: 110px;
    }
  }

  #search-info-container {
    width: 1300px;
    margin: 0 auto;
    margin-top: 40px !important;
    margin-bottom: 250px;

    #header-container {
      display: inline-flex;

      #icon {
        font-size: 22px;
        margin-right: 8px;
        color: #c55c42;
      }

      h2 {
        margin-top: -3px;
      }
    }

    #descr {
      margin-left: 31px;
    }
  }

  .search-cta-container {
    margin-top: 40px;
  }

  #search-res-container {
    width: 1294px;
    margin: 0 auto;
    margin-top: 15px !important;
    margin-bottom: 100px !important;
    min-height: 300px;

    #res-count {
      font-size: 17px;
      margin-left: 2px;
      margin-top: -10px;
      margin-bottom: 15px;
    }

    #why-multiple-results {
      color: #363636;
      margin-top: -8px;
      font-size: 17px;
      margin-left: 2px;
      margin-bottom: 45px;
      border: 1px solid #cfcfcf;
      width: 780px;
      padding: 11px 11px;
      border-radius: 8px;

      #icon {
        color: darkgrey;
        margin-right: 5px;
        margin-bottom: -2px;
      }
    }

    #no-result-container {
      margin-bottom: 40px;
      padding-left: 1px;

      #no-res-count {
        font-size: 19px;
        margin-left: 2px;
        font-weight: bold;

        #no-signal {
          margin-right: 8px;
          margin-bottom: -2px;
          font-size: 20px;
          color: #b13921;
        }
      }

      #meaning {
        margin-top: 8px;
        line-height: 24px;
      }
    }

    .result-item, .result-item-mobile {
      cursor: pointer;
      border-radius: 10px;
      border: 1px solid #cfcfcf;
      padding-left: 25px;
      padding-top: 17px;
      padding-bottom: 22px;
      margin-bottom: 25px;
      background: linear-gradient(184deg, rgb(249 249 249) 0%, rgb(245 245 245) 73%);

      &:hover {
        border: 1px solid #d34c32;
        background: linear-gradient(184deg, rgb(245, 245, 245) 0%, rgb(238, 238, 238) 73%);
      }

      .name {
        margin-top: 5px;
        color: #cc3131;
        width: fit-content;
        cursor: pointer;
        font-size: 20px;
        font-weight: bold;

        &-mobile {
          font-size: 16px;
        }
      }

      .originator {
        margin-top: 25px;
        width: fit-content;
        cursor: pointer;
        font-size: 17px;

        &-mobile {
          font-size: 15px;
        }

        .data, .data-p, .data-mobile, .data-p-mobile {
          font-family: monospace;
          background-color: #e1e1e18c;
          margin-left: 52px;
          padding: 6px 9px;
          border-radius: 5px;

          &-mobile {
            font-size: 17px;
            margin-left: -2px;
            display: grid;
          }

          &-p-mobile {
            font-size: 17px;
            margin-left: 58px;
          }

        }
      }



      .data-p {
        margin-left: 69px !important;
      }

      .date-added, .date-added-mobile {
        width: fit-content;
        cursor: pointer;
        margin-top: 15px;
        font-size: 17px;

        &-mobile {
          font-size: 17px;
          margin-left: -2px;
          display: grid;
        }

        .cal-icon {
          margin-right: 7px;
          margin-bottom: -2px;
          color: #484848;
        }

        .data, .data-mobile {
          font-family: monospace;
          background-color: #e1e1e18c;
          margin-left: 26px;
          padding: 7px 9px;
          padding-top: 7px;
          border-radius: 5px;

          &-mobile {
            font-size: 17px;
            margin-left: -2px;
            display: grid;
          }
        }
      }

      .ext-count {
        margin-top: 15px;
        display: inline-flex;

        .label {
          width: fit-content;
          cursor: pointer;
          font-size: 17px;
        }

        .val {
          font-family: monospace;
          background-color: #e1e1e18c;
          margin-left: 95px;
          padding: 4px 9px;
          border-radius: 5px;
          width: fit-content;
        }
      }

    }

    &-mobile {
      .ext-count {
        display: grid;
      }
    }
  }

  &-mobile {
    width: 100%;
    overflow-x: hidden;

    #compact-search-form-container {
      padding-left: 10px;
      padding-right: 10px;
      width: 96%;

      h1 {
        font-size: 30px !important;
        letter-spacing: -1px;
        font-weight: normal;
      }

      #compact-search-form {
        width: 99% !important;
        margin-top: 15px;

        .inline {
          display: block;
          width: 100%;

          #serial-nr {
            #serial-nr-input, #serial-nr-input-input {
              margin-top: 0px;
              width: 98% !important;
              border-top-right-radius: 0px;
              border-bottom-right-radius: 0px;
            }

            #submit-button {
              margin-top: 0px;
              margin-left: -4px;
              height: 47px;
              width: 55px;

              .submit-icon {
                padding-top: 4px;
                font-size: 19px !important;
              }
            }
          }
        }
      }

      #content-spinner {
        margin-top: 0px;
        margin-bottom: 15px;
        height: 30px;
        width: 99%;
        margin-left: 2px;
      }
    }

    #search-info-container {
      margin-top: 15px !important;
      margin-bottom: 150px;
      padding-left: 10px;
      padding-right: 10px;
      width: 96%;

      #header-container {
        h2 {
          font-size: 22px;
          margin-top: -4px !important;
        }
      }

      #descr {
        margin-left: 26px;
        margin-top: 7px;
        line-height: 26px;
        width: 90%;
      }
    }

    #search-res-container {
      width: 94%;
      margin-top: -10px;
      padding-left: 12px;
      padding-right: 12px;
      min-height: 100px;

      .cta-create-record-search-container-mobile {
        margin-top: 10px;
        padding-left: 12px;
        padding-right: 12px;
        width: 93%;

        #update-types-container {
          .inline {
            .ut {
              width: 90% !important;
            }
          }
        }
      }

      #why-multiple-results {
        width: 93% !important;
        line-height: 24px;
      }
    }
  }

}