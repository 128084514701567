.faq-box-container, .faq-box-container-mobile {
  border: 1px solid #cfcfcf;
  border-radius: 6px;
  width: inherit;
  padding: 8px 17px;
  margin-bottom: 25px;

  .q {
    font-weight: bold;
    padding-top: 4px;
  }
  .a-closed {
    margin-top: 12px;
    margin-bottom: 6px;
    cursor: pointer;

    &:hover {
      color: #cd2328;
    }


    .arrow {
      margin-bottom: -2px;
      font-size: 17px;
      margin-right: 12px;
    }
  }

  .a-open {
    margin-top: 12px;
    margin-bottom: 13px;
    cursor: pointer;

    &:hover {
      color: #cd2328;
    }

    .arrow {
      font-size: 17px;
      margin-top: 2px;
    }

    .answer {
      margin-top: 12px;
      padding-left: 2px;
      cursor: pointer;
    }
  }

  &-mobile {
    .a-closed, .answer {
    }
  }
}