.dispute-recorder-container, .dispute-recorder-container-mobile {
  margin-top: 50px;
  padding-bottom: 50px;

  #descr {
    width: 500px;
  }

  h1 {

  }

  #left {
    float: left;
    width: 590px;
  }

  #right {
    float: right;
  }

  #dispute-ongoing-container {
    justify-content: center;
    display: flex;
  }

  #clearer {
    clear: both;
  }

  #add-notes-container {
    margin-top: 15px;
  }

  #problem-descr-input {
    margin-top: 30px;

    .text-area  {
      width: 470px !important;
    }
  }

  #record-li-container {
    display: grid;
    margin-top: 60px;

    #h {
      font-weight: bold;
      margin-bottom: 4px;
    }

    #descr {
      margin-bottom: 20px;
    }

    .item-selected {
      background: linear-gradient(90deg, rgba(236, 143, 127, 0.68) 0%, rgb(227 186 159) 100%);
    }

    .ext-list-item-container {
      display: inline-flex;
      background-color: #ebebeb;
      border: 1px solid #c7c7c7;
      width: 466px;
      margin-bottom: 12px;
      padding: 8px 12px;
      border-radius: 9px;
      cursor: pointer;

      .index {
        background-color: #e79a84;
        margin-right: 12px;
        border-radius: 15px;
        font-size: 15px;
        height: 23px;
        margin-top: 0px;
        padding: 0px 6px 4px;
        width: 13px;
        text-align: center;
      }
      .type {
        cursor: pointer;
        width: 270px;
      }
      .creation-date {
        text-align: right;
        width: 134px;
      }

      &:hover {
        background: linear-gradient(90deg, rgb(251 155 138 / 68%) 0%, rgb(227 186 159) 100%);
      }

    }
  }

  #submit-dispute-container {
    margin-top: 30px;

    #submit-dispute-button {
      width: 493px;
    }
  }

  #checking-container {
    margin-top: 10px;

    #spinner {
      margin-right: 8px;
      width: 24px;
      height: 24px;
      margin-top: 3px;
    }
    #info {

    }
  }

  &-mobile {

    #left {
      float: none;
      width: 100% !important;

      h1 {
        font-size: 25px !important;
      }

      #descr {
        width: 100%;
      }

      .text-area  {
        width: 94% !important;
        font-size: 15px !important;
      }

      #record-li-container {
        margin-top: 30px !important;
        width: 100%;

        #h {
          width: 98%;
        }

        #descr {
          width: 94%;
          margin-top: 5px;
          margin-bottom: 25px;
        }

        .ext-list-item-container {
          width: 84%;
          .type {
            width: 200px;
          }
        }
      }

      #submit-dispute-container {
        #submit-dispute-button {
          width: 100% !important;
          height: 44px !important;
        }

        #spinner {
          margin-top: 10px;
        }

        #hint-container {
          margin-top: 10px !important;
          width: 94% !important;
        }
      }

    }
    #right {
      float: none;

      #sidebar-explainer {
        margin-top: 60px;
      }
    }

  }
}