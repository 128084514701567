.trx-receipt-container, .trx-receipt-container-mobile {
  width: 540px;
  background: linear-gradient(30deg, rgb(231, 231, 231) 0%, rgb(207, 225, 201) 94%);
  padding: 3px 14px 26px;
  padding-left: 23px;
  margin-top: 20px;
  border-radius: 3px;
  border: 1px solid #cdcdcd;

  #h1 {
    font-weight: bold;
    color: #d34c32;
    margin-top: 19px;
    margin-bottom: 5px;
    font-size: 22px;
  }

  #h2 {
    font-weight: bold;
    margin-bottom: 12px;
    font-size: 18px;
    color: #1e1e1e;
    clear: both;
  }

  .inline {
    margin-top: 5px;

    #trx-receipt-img {
      height: 120px;
      margin-right: 50px;
      margin-top: 18px;
    }

    .details {
      display: grid;

      .detail {
        margin-bottom: 3px;
        background-color: #ffffff7a;
        border-radius: 3px;
        padding: 3px 8px;

        .name {
          font-size: 16px;
          width: 170px;
          padding-left: 8px;
        }

        .val {
          font-size: 16px;
          width: 140px;
          line-break: anywhere;
          text-align: right;
          padding-right: 15px;
        }

        #hash-val {
          padding-top: 4px;
        }
      }
    }
  }


  &-mobile {
    width: 90% !important;
    padding-left: 17px;

    #h1 {
      font-size: 21px !important;
      display: block;
      text-align: center;
    }

    #h2 {
      font-size: 16px;
      text-align: center;
    }

    .inline {
      display: unset !important;

      #trx-receipt-img {
        height: 100px;
        margin-bottom: 15px !important;
        margin-top: 5px !important;
        margin: 0 auto;
        display: block;
      }

      .detail {
        padding-top: 6px !important;
        padding-bottom: 10px !important;
        isplay: inline-flex !important;

        .name {
          font-weight: bold;
          width: 150px;
          margin-top: 4px;
        }

        .val {
          display: block;
          margin-top: 4px;
          width: 120px !important;
          margin-left: 8px;
        }
      }
    }

    h1 {
      font-size: 17px !important;
    }
  }
}