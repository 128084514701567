.verified-creator-container, .verified-creator-container-mobile {
  width: 400px;
  display: inline-block;
  padding: 18px 0px;
  border-radius: 14px;


  #verified-title {

    #icon {
      color: green;
      font-size: 21px;
      margin-bottom: -4px;
      margin-right: 5px;
    }
  }

  .label {
    font-size: 17px;
    margin-bottom: -3px;
    margin-top: 16px;
    color: #557251;
  }

  #verified-name {
    margin-bottom: 4px;
  }

  #address {
    font-size: 17px !important;
    background-color: #dbefdd;
    margin-left: 6px;
    margin-top: 13px !important;
    padding: 5px 13px;
    border-radius: 5px;
    display: inline-block;
    color: green;
  }

  #references-container {
    margin-top: 6px;
    display: inline-flex;

    .ref-icon {
      color: green;
      margin-bottom: -2px;
      margin-right: 5px;
    }

    #related-page {
      font-size: 18px;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    #support-email {
      margin-left: 15px;
      font-size: 18px;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &-mobile {
    width: 98%;
    display: inline-block;
    padding: 14px 0px;
    padding-bottom: 20px;

    #verified-title {
      font-size: 17px !important;

      #icon {
        font-size: 19px;
        margin-bottom: -4px;
        margin-right: 8px;
      }
    }

    .label {
      font-size: 17px;
      margin-top: 20px;
    }

    #verified-name {
      margin-top: 6px;
      margin-bottom: 4px;
      font-size: 18px;
    }

    #address {

    }

    #references-container {
      margin-top: 6px;

      .ref-icon {
        margin-bottom: -3px;
        margin-right: 7px;
      }

    }
  }
}