#toc-container, #toc-container-mobile {
  padding-bottom: 100px !important;

  #intro-descr {
    margin-bottom: 30px;
    width: 960px;
  }

  .paragraph {
    width: 960px;
  }

  h2 {
    margin-top: 40px;
    color: #c7462d;
  }

  h3 {
    margin-top: 30px;
  }

  &-mobile {
    #intro-descr {
      width: 100%;
    }

    .paragraph {
      width: 100%;
    }
  }
}