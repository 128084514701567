.check-authentic-container, .check-authentic-container-mobile {
  padding-top: 100px;
  padding-bottom: 40px;
  background: linear-gradient(180deg, rgb(189 189 189) 0%, rgba(241, 241, 241, 0.25) 9%, rgb(255, 255, 255) 12%);

  .hl {
    color: #d34c32;
  }

  #content {
    width: 1220px;
    margin: 0 auto;
    text-align: center;

    #headline {
      font-family: "BS", "Arial", sans-serif;
      transform: scaleY(0.9);
      font-size: 40px;
      letter-spacing: -1px;
      word-spacing: 2px;
    }

    #descr {
      margin-top: 40px !important;
      font-size: 20px;
      line-height: 28px;
      width: 950px;
      margin: 0 auto;
    }

    #record-examples {
      margin-top: 60px;
      margin-bottom: 60px;

      #h {
        font-family: "BS", "Arial", sans-serif;
        transform: scaleY(0.9);
        font-size: 27px;
        letter-spacing: -1px;
        word-spacing: 3px;
      }

      #examples-container {
        margin-top: 25px;

        .example {
          border: 1px solid #cdcdcd;
          border-radius: 10px;
          width: 290px;
          height: 110px;
          box-shadow: 0px 1px 2px 2px rgba(213, 213, 213, 0.2196078431);
          overflow: hidden; /* This will hide the content that overflows the container */


          .img-bg {
            position: absolute;
            width: 201px;
            height: auto;
          }

          #jbl-example-img {
            margin-left: -48px;
            margin-top: 9px;
            width: 190px;
          }

          #rolex-example-img, #aquanaut-example-img, #seiko-example-img {
            margin-left: -4px;
            margin-top: 11px;
            width: 137px;
          }

          #mb-example-img {
            margin-left: -56px;
            margin-top: 5px;
          }

          #watch-example-img {
            margin-left: -43px;
            width: 182px;
            margin-top: 9px;
          }

          .inner {
            width: 267px;
            height: 80px;
            text-align: left;
            padding: 14px 0px 14px 20px;
            margin: 0 auto;
            margin-top: 1px;
            border-radius: 7px;
            background: linear-gradient(353deg, rgb(243 243 243) 0%, rgb(255 255 255) 100%);

            .cat {
              font-size: 16px;
              font-weight: bold;
              color: grey;
            }

            .name {
              width: 120px;
            }
          }

          &:hover {
            cursor: pointer;
            background: linear-gradient(179deg, rgb(237 122 122) 0%, rgb(199 41 70) 100%);
            box-shadow: 0px 1px 2px 2px rgb(255 202 202 / 47%);
            border: 1px solid #a6a6a6;

            .cat, .name, .icon {
              cursor: pointer;
            }
          }

        }

        #jbl, #ps5 {
          margin-right: 50px;
        }
      }

    }

    #see-on-yt {
      margin-top: 50px !important;
      border: 1px solid #ff7c7c;
      width: fit-content;
      margin: 0 auto;
      padding: 6px 18px;
      border-radius: 18px;
      cursor: pointer;

      &:hover {
        background-color: #ffeeee;
      }

      #icon {
        color: red;
        margin-bottom: -4px;
        font-size: 22px;
        margin-right: 5px;
      }
    }
  }

  &-mobile {
    padding-top: 50px;
    padding-bottom: 70px;

    #content {
      width: 95%;
      margin: 0 auto;
      text-align: center;

      #headline {
        font-size: 33px;
        line-height: 35px;
      }

      #descr {
        margin-top: 40px !important;
        font-size: 18px;
        line-height: 26px;
        width: 96%;
        margin: 0 auto;
      }

      #record-examples {
        margin-top: 50px;
        margin-bottom: 30px;

        #h {
          font-family: "BS", "Arial", sans-serif;
          transform: scaleY(0.9);
          font-size: 25px;
          letter-spacing: -1px;
        }

        #examples-container {
          margin-top: 25px;
          display: inline-block;

          .example {
            width: 340px;
            height: 100px;
            overflow: hidden;
            margin-bottom: 20px !important;
            margin: 0 auto;


            .img-bg {
              position: absolute;
              width: 160px;
              height: auto;
            }

            #jbl-example-img {
              margin-left: -10px;
              margin-right: 0;
              margin-top: -5px;
              width: 180px;
            }

            #mb-example-img {
              margin-left: 0px;
              margin-top: -1px;
              width: 164px;
            }

            #watch-example-img {
              width: 140px;
              margin-left: 18px;
              margin-top: 7px;
            }

            #rolex-example-img, #aquanaut-example-img, #seiko-example-img {
              margin-left: -14px;
              margin-top: 4px;
              width: 147px;
            }


            .inner {
              width: 316px;
              height: 80px;
              text-align: left;
              padding: 14px 0px 14px 20px;
              margin: 0 auto;
              margin-top: 2px;
              border-radius: 7px;
              background: linear-gradient(353deg, rgb(243 243 243) 0%, rgb(255 255 255) 100%);

              .cat {
                font-size: 16px;
                font-weight: bold;
                color: grey;

              }

              .name {
                font-size: 19px;
                width: fit-content;
              }
            }

            &:hover {
              cursor: pointer;
              background: linear-gradient(179deg, rgb(255, 0, 0) 0%, rgb(255 154 154) 100%);
              box-shadow: 0px 1px 2px 2px #ffb5b578;
              border: 1px solid #a6a6a6;

              .cat, .name, .icon {
                cursor: pointer;
              }
            }

          }

         #jbl, #ps5, #seiko {
           margin-right: 5% !important;
         }

        }

      }

      #see-on-yt {
        width: 80%;
      }
    }

  }

}