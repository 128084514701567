.loss-notice-reporter-container, .loss-notice-reporter-container-mobile {
  display: inline-flex;
  margin-bottom: 80px;

  #left {
    width: 630px;
    margin-right: 130px;

    #when-bulletpoints {
      margin-top: 20px;

      .bp {
        display: inline-flex;
        margin-bottom: 10px;

        .bullet {
          width: 15px;
          height: 14px;
          background: linear-gradient(310deg, rgb(203, 72, 47) 30%, rgb(189 54 85) 100%);
          margin-right: 13px;
          margin-top: 7px;
          border-radius: 3px;
        }
        .text {
          width: 500px;
        }
      }
    }

    #report-form {
      display: block;
      width: 530px;

      #descr {
        width: 100%;
      }

      #loss-date-input-container {
        margin-top: 40px;

        #loss-date-input {
          padding-top: 12px;
          padding-bottom: 10px;
          padding-left: 12px;
          margin-top: 8px;
          margin-bottom: 6px;
          border: 1px solid #949494 !important;
          background: linear-gradient(155deg, rgb(245, 245, 245) 0%, rgb(239, 246, 247) 100%);
          outline: none;
          border-radius: 10px;
          width: 97%;
          font-size: 18px;
        }
      }

      #details-container {
        margin-top: 40px;

        #details-hints {
          margin-top: 10px;
          width: 96%;
        }

        #details-input {
          width: 96%;
          margin-top: 14px;
        }
      }

      #email-input-container {
        margin-top: 40px;

        #email-input {
          font-size: 17px;
          width: 100%;
        }
      }

      #report-info-text {
        margin-top: 40px;
        width: 100%;
        font-size: 17px;
        color: #585858;
        line-height: 25px;
        text-align: center;
      }

      #button-container {
        width: 100%;
        margin-top: 10px;

        #report-button {
          width: 100%;
          font-size: 18px;
          height: 47px !important;
          margin-bottom: 15px;
        }

        #spinner {
          display: block;
          width: 23px !important;
          margin-top: 10px !important;
          margin: 0 auto;
        }
      }

    }

    #already-reported-container {
      display: block;
      width: 580px;

      #descr {
        width: 100%;
      }

      #mark-found-info {
        margin-top: 30px;
      }
    }

    #checking-existing-container {
      display: block;
      width: 530px;

      #spinner {
        display: block;
        width: 23px !important;
        margin-top: 1px !important;
        margin: 0 auto;
        margin-left: 10px;
      }
    }
  }

  #right {
    margin-left: 100px;
    #sidebar-explainer {
      margin-top: 20px;
    }
  }

  &-mobile {
    #left {
      #report-form {
        h1 {
          font-size: 25px !important;
        }

        #descr {
          width: 68%;
        }
      }

      #when-bulletpoints {
        margin-top: 30px;

        .bp {
          .bullet {
            width: 19px;
          }
          width: 70% !important;
        }
      }

      #loss-date-input-container {
        #loss-date-input {
          width: 65% !important;
          font-size: 18px;
        }
      }

      #details-container {
        .hint-container-mobile {
          width: 64% !important;
        }

        #details-input {
          width: 64% !important;
        }
      }

      #report-info-text {
        width: 68% !important;
      }

      #report-button {
        width: 68% !important;
      }
    }
  }
}