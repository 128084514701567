#consent-banner-container, #consent-banner-container-mobile {
  width: 740px;
  background: linear-gradient(179deg, rgb(249, 249, 249) 0%, rgba(247, 247, 247, 0.92) 100%);
  border: 1px solid #cdcdcd;
  padding: 25px 29px;
  padding-right: 0px;
  border-radius: 10px;
  position: fixed;
  margin-left: -401px;
  bottom: 35px;
  left: 50%;
  z-index: 1;

  h3 {
    #cookie-icon {
      margin-bottom: -2px;
      font-size: 19px;
      margin-right: 5px;
      color: #8d2727;
    }
  }

  #text {
    font-size: 17px;
    margin-bottom: 10px;
    margin-top: 15px;
    line-height: 24px;
  }

  #details-container {
    #details-h {
      font-size: 17px;
      font-weight: bold;
      margin-top: 15px;
    }

    #details-content {
      margin-top: 5px;
      line-height: 24px;

      a {
        font-size: 17px;
      }
    }
  }

  #buttons-container {
    margin-top: 10px;
    margin-left: -5px;

    #accept-button {
      margin-right: 10px;
      min-width: 140px;
      font-size: 16px;
      height: 37px;
      padding-top: 9px;
      padding-right: 22px;

      .text-icon {
        color: #37c137;
      }
    }
    #reject-button {
      min-width: 140px;
      font-size: 16px;
      height: 37px;
      padding-top: 9px;
      padding-right: 22px;

      .text-icon {
        font-size: 12px;
        margin-bottom: 0px;
        margin-right: 11px;
      }
    }
  }

  &-mobile {
    margin: 0 auto;
    margin-top: 40px;
    width: 87%;
    padding: 25px 22px;
    padding-right: 6px;
    left: 2.3%;

    #accept-button, #reject-button {
      height: 41px !important;
    }
  }

}