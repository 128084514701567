#testimonials-container, #testimonials-container-mobile {
  padding-top: 70px;
  padding-bottom: 100px;
  background: linear-gradient(180deg, rgb(191 191 191) 0%, rgb(235 235 235) 7%, rgb(255, 255, 255) 14%);

  #content {
    margin: 0 auto;
    display: grid;
    justify-content: center;

    #headline {
      text-align: center;
      font-family: "BS", "Arial", sans-serif;
      transform: scaleY(0.9);
      font-size: 37px;
      letter-spacing: -1px;
      word-spacing: 2px;
    }

    #sub-content {
      display: flex;
      margin-top: 40px;

      #testimonial-1 {
        margin-right: 30px;
      }

      .testimonial {
        width: 540px;
        background: linear-gradient(351deg, #ffffff, rgb(255 247 247 / 49%) 100%);
        border-radius: 10px;
        border: 1px solid #e1e1e1;
        padding: 22px 17px 14px 24px;

        .text {
          .q1 {
            color: #ed5e43;
            font-size: 80px;
            margin-right: 15px !important;
            margin-top: 24px;
          }

          .quote {
            font-size: 19px;
            line-height: 28px;
            min-height: 110px;
          }

        }

        .profile-info {
          display: inline-flex;
          margin-top: 20px;
          margin-left: 37px;

          .profile-pic-container {
            .profile-pic {
              border-radius: 30px;
              width: 60px;
              margin-right: 20px;
            }
          }

          .profile-text {
            margin-top: -5px;
            text-align: left;
            width: 300px;

            .name {
              font-size: 19px;
            }

            .sm-icons {
              justify-content: center;
              margin-top: 3px;

              .ref-sm-icon {
                font-size: 22px;
                color: #4e4e4e;

                &:hover {
                  color: #d34c32;
                  cursor: pointer;
                }
              }

              .yt-icon {
                margin-left: 5px;
                font-size: 23px;
              }

              .account {
                font-size: 17px;
                margin-top: -3px;
                margin-left: 3px;

                &:hover {
                  color: #d34c32;
                  cursor: pointer;
                }
              }

            }
          }

        }
      }
    }


  }

  &-mobile {
    padding-top: 70px;
    padding-bottom: 70px;

    #content {
      margin: 0 auto;
      display: block;
      justify-content: center;

      #headline {
        font-size: 33px !important;
        line-height: 32px;
        width: 90%;
        margin: 0 auto;
      }

      #sub-content {
        display: block;
        width: 95%;
        margin: 0 auto;
        margin-top: 30px !important;

        #testimonial-1 {
          margin-bottom: 25px;
          margin-right: 9px;
        }

        .testimonial {
          margin: 0 auto;
          width: 85%;
          padding: 22px 17px 14px 24px;

          .text {
            .q1 {
              color: #ed5e43;
              font-size: 80px;
              margin-right: 15px !important;
              margin-top: 24px;
            }

            .quote {
              font-size: 18px;
              line-height: 26px;
              width: 80%;
            }

          }

          .profile-info {
            display: inline-flex;
            margin-top: 30px;
            margin-left: 37px;

            .profile-pic-container {
              .profile-pic {
                width: 60px;
                margin-right: 20px;
              }
            }

            .profile-text {
              margin-top: -5px;
              text-align: left;
              width: fit-content;

              .name {
                font-size: 17px;
              }
              .info {
                margin-top: -3px;
                font-size: 17px;
                color: #4c4c4c;
                line-height: 21px;
              }

              .sm-icons {
                justify-content: center;
                margin-top: 3px;

                .ref-sm-icon {
                  font-size: 22px;
                  color: #4e4e4e;

                  &:hover {
                    color: #d34c32;
                    cursor: pointer;
                  }
                }

                .yt-icon {
                  margin-left: 5px;
                  font-size: 23px;
                }
              }
            }

          }
        }
      }

    }
  }

}