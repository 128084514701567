.feedback {
  border-radius: 10px;
  padding: 6px 8px;
  margin-top: 25px;
  margin-bottom: 20px;
  min-width: 400px;
  width: fit-content;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 17px;

  p {
    font-size: 17px;
  }


  &.success {
    background-color: #c6fdbd;
    color: #276f03;
  }

  &.error {
    background: linear-gradient(180deg, rgb(252, 175, 175) 0%, rgb(255 167 167) 88%);
    color: #6f0303;
  }

  &.centered {
    margin: 0 auto;
  }
}
