.cta-create-record-search-container, .cta-create-record-search-container-mobile {
  width: 99%;
  background: linear-gradient(358deg, rgb(255, 255, 255) 39%, rgb(245 245 245) 100%);
  padding-left: 38px;
  padding-top: 38px;
  padding-bottom: 27px;
  margin-top: 50px;
  border-radius: 7px;
  border: 1px solid #cfcfcf;

  .hl {
    color: #d34c32;
    margin-left: 8px;
    margin-right: 8px;
  }

  #headline {
    font-family: "BS", "Arial", sans-serif;
    transform: scaleY(0.9);
    letter-spacing: -1px;
    font-size: 32px;
  }

  #content {
    display: inline-flex;
  }

  #l {
    width: 600px !important;

    #descr {
      margin-top: 20px !important;
      font-size: 18px;
      line-height: 25px;
    }

    #bp-container {
      text-align: left;
      margin-top: 30px;

      .bp-item {
        display: inline-flex;

        .bullet {
          width: 15px;
          height: 14px;
          background: linear-gradient(310deg, rgb(203, 72, 47) 30%, rgb(189 54 85) 100%);
          margin-right: 13px;
          margin-top: 7px;
          border-radius: 3px;
        }
        .bp {
          margin-bottom: 15px;
          width: 100%;
        }
      }

      #bp2 {
        margin-bottom: 15px;
      }

      #updates-context-container {
        margin-top: -3px;
        margin-bottom: 30px;
        margin-left: 20px;

        #update-types-container {
          margin-top: -3px;
          margin-bottom: 30px;
          margin-left: 8px;

          #label {
            font-size: 17px;
            margin-bottom: 6px;
            color: #d15546;
          }

          .ut {
            background: linear-gradient(340deg, rgb(243 243 243) 30%, rgb(239 239 239) 100%);
            padding: 2px 11px;
            width: fit-content;
            border-radius: 6px;
            font-size: 17px;
            margin-right: 10px;
          }
        }
      }

    }

    #footnotes {
      margin-top: 40px;

      p {
        font-size: 16px;
        color: #9a9a9a;
        margin-bottom: 1px;
        line-height: 20px;
      }
    }

  }

  #r {
    margin-left: 130px;
    width: 430px;

    #record-presentation {
      width: 100%;
      text-align: center;

      #img-container {
        #record-img {
          height: 150px;
          margin-top: -25px;
          position: relative;
          margin-bottom: 20px;
        }
      }

      #name {
        font-size: 24px;
      }

      #cat {
        font-size: 17px;
        color: grey;
      }
    }

    #price-box {
      width: 100%;
      text-align: center;

      #price {
        margin-top: 10px;
        font-size: 20px;
        margin-bottom: 10px;
      }

      #guarantees {
        margin-top: 35px;

        .guarantee {
          background: linear-gradient(340deg, rgb(243 243 243) 30%, rgb(239 239 239) 100%);
          padding: 5px 13px;
          width: -moz-fit-content;
          width: 100%;
          border-radius: 6px;
          margin-bottom: 12px;
          font-size: 17px;
        }

        #shield-icon {
          margin-right: 12px;
          margin-bottom: -2px;
          color: #0bcb0b;
        }

        #speed-icon {
          margin-right: 12px;
          margin-bottom: -2px;
        }
      }
    }

    #order-button {
      margin-top: 30px;
      width: 106%;
      padding-top: 11px;
    }

    #payment-methods {
      margin-top: 10px;
      text-align: center;
      width: 106%;

      #cc {
        width: 75px;
        margin-right: 20px;
      }
      #paypal {
        width: 85px;
      }
    }

    #support-infos-container {
      margin-top: 22px;
      width: 106%;

      #hotline {
        font-size: 17px;
        margin-top: 14px;
        text-align: center;
        color: #a5a5a5;

        #icon {
          margin-bottom: -2px;
        }
      }
    }
  }

  &-mobile {
    display: inline-block;
    width: 90%;
    padding: 30px 17px 27px 17px;
    margin-top: 22px;
    border-radius: 7px;
    margin-bottom: 60px;

    #headline {
      font-size: 30px;
      line-height: 33px;
      text-align: center;
    }

    #content {
      display: block;
    }

    #l {
      width: 100% !important;

      #descr {
        text-align: center;
        margin-top: 20px !important;
        font-size: 18px;
        line-height: 25px;
      }

      #bp-container {
        text-align: left;
        margin-top: 30px;

        .bp-item {
          display: inline-flex;

          .bullet {
            width: 15px;
            height: 14px;
            margin-right: 13px;
            margin-top: 7px;
          }
          .bp {
            margin-bottom: 15px;
            width: 100%;
          }
        }

        #updates-context-container {
          margin-top: -3px;
          margin-bottom: 30px;
          margin-left: 20px;

          #update-types-container {
            margin-top: -3px;
            margin-bottom: 30px;
            margin-left: 8px;

            #label {
              font-size: 17px;
              margin-bottom: 6px;
              color: #d15546;
              line-height: 23px;
            }

            .inline {
              display: block;

              .ut {
                padding: 5px 11px;
                width: 86%;
                border-radius: 6px;
                font-size: 17px;
                margin-bottom: 8px;
              }
            }

          }
        }

      }

      #footnotes {
        margin-top: 20px;
        text-align: center;

      }

    }

    #r {
      width: 100%;
      display: inline-block;
      margin-left: 0;
      margin-top: 50px;
      float: none;

      #record-presentation {
        text-align: center;

        #img-container {
          #record-img {
            height: 130px;
            margin-top: 0px;
            margin-bottom: 20px;
          }
        }

        #name {
          font-size: 24px;
        }

        #cat {
          font-size: 17px;
          color: grey;
        }
      }

      #price-box {
        text-align: center;
        #price {
          margin-top: 10px;
          font-size: 20px;
          margin-bottom: 10px;
        }

        #guarantees {
          margin-top: 35px;

          .guarantee {
            padding: 10px 13px;
            width: 92%;
            border-radius: 6px;
            margin-bottom: 12px;
            font-size: 17px;
          }

          #shield-icon {
            margin-right: 12px;
            margin-bottom: -2px;
            color: #0bcb0b;
          }

          #speed-icon {
            margin-right: 12px;
            margin-bottom: -2px;
          }
        }
      }

      #order-button {
        margin-top: 15px;
        width: 100% !important;
        padding-top: 11px;
        height: 46px !important;
        font-size: 18px;
      }

      #payment-methods {
        margin-top: 10px;
        text-align: center;

        #cc {
          width: 70px;
          margin-right: 20px;
        }
        #paypal {
          width: 76px;
        }
      }


    }
  }
}