.branch-creation-container, .branch-creation-container-mobile {
  margin-bottom: 150px;

  .l1 {
    .record-type {
      background: linear-gradient(63deg, rgb(235 129 169) 0%, rgb(255 136 161) 100%);
      height: 26px;
      margin-top: -1px;
    }

    .added-on-container {
      height: 24px;
      margin-top: -1px;
      margin-left: -2px;
      border-radius: 3px;
      padding: 5px 15px 5px 10px;
      border-left: none !important;
      border: 1px solid #EB81A9FF;

      .change-date {
        .cal-icon {
          margin-right: 7px;
          margin-bottom: -2px;
          color: #484848;
        }

        .data {
          font-family: monospace;
          margin-left: 8px;
        }
      }
    }
  }

  .l2 {
    margin-top: 20px;

    .originator {
      display: inline-flex;

      .label {
        width: 62px;
        text-align: right;
        margin-top: 3px;
        margin-right: 2px;
      }

      .data {
        margin-left: 15px !important;
      }
    }
  }

  .l3 {
    margin-top: 25px;

    .branch-link {
      display: contents;
      margin-top: 13px;
      color: #cd2328;

      &:hover {
        text-decoration: underline !important;
        cursor: pointer;
      }

      .link-icon {
        margin-bottom: -2px;
        margin-right: 8px;
      }
    }

  }


  &-mobile {
    .record-content {

      .inline {
        display: inline-flex;
      }

      .l1 {
        .record-type {
          font-size: 16px !important;
          padding-top: 7px !important;
          padding-bottom: 1px !important;
        }

        .added-on-container {
          display: inline-flex;
          height: 20px;
          padding-top: 7px;

          .change-date {
            display: inline-flex;

            .data {
              margin-left: 8px !important;
            }
          }
        }
      }

      .l2 {
        margin-top: 25px;

        .originator {

          .label {
            width: fit-content;
          }

          .data {
            margin-top: -4px !important;
            margin-left: 8px !important;
            font-size: 16px;
          }
        }
      }

      .l3 {
        .branch-link {
          font-size: 16px !important;
          margin-left: 1px;
          margin-top: 18px;
        }
      }

    }
  }

}