.side-bar-explainer-container, .side-bar-explainer-container-mobile {
  width: 380px;
  background: linear-gradient(32deg, rgb(251, 251, 251) 0%, rgb(245 245 245 / 11%) 94%);
  border: 1px solid #d9d9d9 !important;
  padding: 11px 28px 20px;
  height: fit-content;
  border-radius: 10px;

  .sidebar-h1, .sidebar-h1-mobile {
    font-size: 23px !important;
    font-weight: normal;
    margin-top: 15px;
    margin-bottom: 30px;
  }

  .steps, .steps-mobile {
    .step-container, .step-container-mobile {
      margin-bottom: 20px;

      .name {
        display: inline-flex;
        margin-bottom: 10px;

        #nr, #nr-mobile {
          color: black;
          background: #d9d9d9;
          border-radius: 14px;
          padding: 0px 9px;
          font-weight: normal;
          font-size: 14px;
          margin-right: 10px;
        }

        #val {
          font-size: 18px;
          font-weight: bold;
        }
      }

      .descr {
        font-size: 17px;
        line-height: 26px;
      }

    }
  }

  &-mobile {
    width: 91% !important;
    padding: 5px 15px 15px;
  }

}