.record-summary, .record-summary-mobile {
  position: relative;
  z-index: 1;
  margin-bottom: 23px;
  background: linear-gradient(8deg, rgb(255 255 255 / 34%) 0%, rgb(245 245 245 / 54%) 100%);
  height: fit-content;
  padding-top: 22px;
  padding-bottom: 25px;
  border-radius: 20px;
  border: 1px solid #cfcfcf;

  #name {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 14px;
  }

  .stats {
    margin-top: 35px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;

    .percentage-disputes {
      margin-left: 25px;
    }

    .modifications {
      margin-right: 10px;
    }

    .reported-damages, .purchase-info,
    .modifications, .record-activation {
      cursor: pointer;

      &:hover {
        .text-container {
          .icon, .value {
            cursor: pointer;
          }

          .value {
            color: #bd4a34;
            cursor: pointer;
          }

          .label {
            color: #bd4a34;
            cursor: pointer;
          }
        }
      }
    }

    .purchase-info {
      width: fit-content !important;
      margin-right: 40px;
    }

    #owners {
      width: 110px !important;
      margin-left: 10px;
    }

    .icon-text-info {
      width: 170px;

      .text-container {
        margin-top: -2px;
        text-align: center;

        .icon {
          margin-right: 5px;
          margin-bottom: -7px;
          width: 45px;
        }

        .label {
          font-size: 16px;
          font-weight: bold;
          color: #202020;
          margin-top: 6px;
        }

        .value {
          text-align: center;


          #disputes-icon {
            color: #d34c32;
            margin-bottom: -2px;
            font-size: 18px;
          }
        }
      }

    }


  }

  &-mobile {
    z-index: 0;
    padding-top: 20px;
    padding-bottom: 20px;

    #name {
      font-size: 20px;
      margin-bottom: 20px;
    }

    .stats {
      display: block;
      margin-bottom: 15px;


      #owners {
        width: 100% !important;
        margin-left: 0px;
      }

      .purchase-info {
        width: 100% !important;
        margin-bottom: 35px !important;
      }

      .icon-text-info {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;

        .icon {
          margin-right: 5px !important;
          margin-bottom: -5px !important;
          width: 38px !important;
        }

        .text-container {
          .label {
            font-size: 17px;
            margin-top: 8px !important;
          }
        }
      }

      #calendar-icon {
        font-size: 15px;
      }

      #hands-changed-icon {
        font-size: 18px;
        margin-top: -1px !important;
      }

      .modifications {
        #mods-icon {
          font-size: 18px !important;
        }
        .text-container {
          .label {
            margin-top: 6px !important;
          }
        }
      }

      .reported-damages {
        margin-bottom: 5px !important;
        pointer: cursor;

        #damages-icon {
          margin-top: 1px !important;
        }
      }

      .percentage-disputes {
        margin-left: 0px;
        margin-top: 35px;
      }

      .text-container {
        .value {
          font-size: 18px !important;
          margin-top: 1px !important;
        }
      }

    }
  }

}