.form-steps-container, .form-steps-container-mobile {
  margin-top: 50px;

  .steps {
    display: inline-flex;

    .step {
      .nr {
        font-size: 15px;
        border: 1px solid #707070;
        border-radius: 18px;
        width: 27px;
        height: 28px;
        margin-right: 8px;
        margin-top: 3px !important;
      }

      .label {
        font-size: 17px;

        &.passive, &.done {
          color: #787878;
        }
      }

      .step-bar {
        height: 4px;
        border-radius: 6px;

        &.active {
          background-color: black;
        }
        &.passive {
          background-color: #9f9f9f;
        }
        &.done {
          background-color: #3cc966;
        }

      }

      &:not(:last-child) {
        margin-right: 30px;
      }

    }
  }

  &-mobile {
    margin-top: 30px;

    .steps {

      .step {
        .inline {
          display: inline-flex;
        }
        .nr {
          font-size: 15px;
          width: 24px;
          height: 25px;
          margin-right: 5px;
        }

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }
}