#getting-started-container,
#getting-started-container-mobile {

  #h1-gs {

  }

  .chapter {
    margin-top: 40px;
    margin-bottom: -8px;
    font-size: 26px;
    color: #d34c3d;
  }

  #getting-started-explainer {
    width: 900px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  #yt-video {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  #blockchain-chapter {
    margin-top: 100px;
  }

  .explanation-section {
    margin-top: 60px;
    margin-bottom: 30px;
  }

  .h2c {
    font-weight: bold;
    margin-top: 60px !important;
    margin-bottom: 20px !important;
  }

  .pc {
    width: 680px;
  }

  .get-tool-container {
    width: fit-content;
    margin-top: 20px !important;
    margin-bottom: 40px !important;
    border-radius: 12px;
    border: 1px solid #c5c5c5;;

    .inline {
      padding: 7px 25px 11px 15px;

      .logo {
        height: 32px;
        margin-top: 4px;
      }
      .text {
        margin-top: 7px;
        margin-left: 15px;
      }
    }

    :hover {
      cursor: pointer;
      background: linear-gradient(107deg, rgb(255 242 229) 0%, rgb(238, 238, 238) 81%);
      border-radius: 12px;
    }
  }

  &-mobile {

    #h1-gs {
      margin-top: 15px !important;
      margin: 0 auto;
    }

    #getting-started-explainer {
      width: 100%;
      margin-top: 30px;
      margin-bottom: 0px;
    }

    #yt-video {
      margin-top: 30px;
      margin-bottom: 0px;
    }

    .pc {
      width: 98%;
    }

    .get-tool-container {
      padding-top: 5px;
      padding-bottom: 5px;
      .inline {
        display: block;

        .logo {
          height: 30px;
        }
        .text {
          margin-top: 5px;
          margin-left: 0px;
        }
      }
    }

    .explanation-section {
      margin-bottom: 80px;
    }
  }
}