.due-public-setter-container, .due-public-setter-container-mobile {
  margin-top: 30px;
  margin-bottom: 70px;


  #descr {
    font-size: 16px;
  }

  #hint-container {
    width: 630px;
    margin-top: 13px;
  }

  #submit-container {
    margin-top: 50px;

    #submit-button {
      width: 393px;
      margin-top: 15px;
    }
  }

  #reload-info {
    font-size: 15px;
    margin-top: 20px;
    padding-left: 5px;
  }

  #get-wallet-container {
    margin-top: 30px;
    padding-left: 3px;

    #h {
      font-size: 16px;
      font-weight: bold;
    }
    #descr {
      font-size: 16px;
      margin-top: 8px;
    }
  }

  &-mobile {
    #submit-container {
      #submit-button {
        width: 100%;
      }
    }
  }

}