.infobox-container, .infobox-container-mobile {
  width: 90%;
  margin: 0 auto;
  border-radius: 3px;
  padding: 2px 40px 18px 30px;

  h1 {
    margin-top: 23px;
    margin-bottom: 15px;
  }

  h2 {
    font-size: 24px !important;
  }

  p {
    line-height: 24px !important;
  }

  .descr {
    font-size: 18px;
    max-width: none;
    width: 100% !important;
  }

  .asterisk-text {
    font-size: 15px;
    color: #858585;
    margin-top: 10px;
  }

  &-mobile {
    h1 {
      margin-top: 18px !important;
    }
    width: 93% !important;
    padding-left: 12px !important;
    padding-right: 12px !important;

    .descr {
      font-size: 17px !important;
    }
  }

}