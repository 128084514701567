#nav-container, #nav-container-mobile, #nav-container {
  position: relative;
  z-index: 1;
  width: 100%;
  background: linear-gradient(174deg, rgba(27,27,27,1) 0%, rgb(36 36 36) 100%);
  text-align: center;
  height: 63px;
  padding-top: 11px;

   #nav-content {
     display: inline-flex;
     height: 50px;
     width: 1300px;
     justify-content: space-between;
     margin: 0 auto;

     #links-container {
       display: inline-flex;
       padding-top: 15px;
       margin-right: 20px;
       margin-left: 20px;

       .link {
         color: white;
         margin-right: 30px;
         width: max-content;

         p {
           cursor: pointer;
           font-size: 17px;
         }

         &:hover {
           color: #ff6c6f;
           text-decoration: none;
         }
       }

       .nav-icon {
         margin-bottom: -2px;
         font-size: 17px;
         margin-right: 6px;
         color: #ff684b;
       }

     }

     #question-icon-wallet {
       font-size: 20px;
     }
  }

  .login-button-container, .account-button-container {
    display: contents;
    width: 189px;
    height: 36px;
    margin-right: 3px;
    margin-top: -50px;
    text-align: center;
    background-color: #f0f0f0 !important;
    border-radius: 3px;

    .login-btn, .account-btn {
      width: 80px;
      border: 1px solid #d34c32;
      border-radius: 11px;
      padding: 6px 8px 5px 14px;
      font-size: 16px;
      background: linear-gradient(115deg, rgb(207 94 73) 0%, rgb(199 82 60) 100%);
      margin-top: 8px;
      color: white;
      cursor: pointer;

      &:hover {
        background: linear-gradient(115deg, rgb(221 104 82) 0%, rgb(227 107 83) 100%);
      }

      .icon {
        font-size: 17px;
        margin-right: 6px;
        margin-top: 5px;
      }
    }

    #tooltipContainer {
      margin-top: -30px;
      margin-right: -30px;
      cursor: pointer;
    }
  }

  .account-button-container {
    .account-btn {
      width: 116px;
    }
  }

  &-mobile {
    position: sticky;
    top: 0px;
    z-index: 10;
    height: 45px;
    padding-top: 11px;

    #nav-content {
      width: 94% !important;
      display: inline-flex;
      padding-left: 2px;
      margin: 0 auto;

      .logo-container {
        #spinner-container {
          margin-top: -21px !important;
        }
        .logo {
          padding-top: 10px;
        }
      }

      #hamburger-icon {
        position: absolute;
        font-size: 24px;
        color: #eb4120;
        padding-top: 6px;
        right: 21px;
        cursor: pointer;
      }
    }

    #side-menu {
      position: relative;
      z-index: 2;
      background: linear-gradient(179deg, rgb(34 34 34 / 96%) 0%, rgb(4 4 4) 100%);
      width: 290px;
      position: absolute;
      right: 0px;
      top: 56px;
      padding-top: 30px;
      padding-bottom: 20px;
      height: 100%;
      box-shadow: -2px 20px 20px 4px #00000091;

      div#links-container.closed-connected {
        margin-top: -125px !important;
      }

      div#links-container.open {
        margin-top: 180px !important;
      }

      .login-button-container {
        .login-btn {
          width: 69%;
          text-align: center;
          font-size: 18px;
          height: 32px;
          padding-top: 7px;
          padding-left: 2px;
          display: inline-block;
          margin-left: 0px;

          .icon {
            font-size: 18px;
            margin-right: 8px;
            margin-bottom: -2px;
          }
        }
      }

      .account-button-container {
        .account-btn {
          width: 54%;
          padding-left: 48px;
        }
      }

      #links-container {
        margin-top: 40px;
        text-align: left;

        .link {
          width: fit-content !important;
          color: white;
          position: relative;
          margin-left: 24px;
          display: block;
          margin-bottom: 20px;

          .nav-icon {
            margin-right: 10px;
            margin-bottom: -3px;
            color: #ff684b;
          }

          p {
            font-size: 19px;
          }

        }
      }
    }
  }

}
