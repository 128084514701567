.content-container, .content-container-mobile {
  #l {
    float: left;
    width: 650px;
    padding-bottom: 70px;

    #email-box {
      display: inline-flex;
      width: 609px;
      margin-top: 30px;
      padding: 20px 20px;
      cursor: pointer;
      border: 1px solid #cfcfcf;
      border-radius: 8px;

      &:hover {
        background-color: #fbfbfb;
      }

      #icon {
        font-size: 50px;
        margin-top: 0px;

        &:hover {
          color: #cd2328;
        }
      }

      #descr-container {
        margin-left: 20px;
        margin-top: 8px;

        #h {
          font-weight: bold;
          cursor: pointer;
        }
        #descr {
          cursor: pointer;
          margin-top: 12px;
        }
      }
    }

    #quick-fix-container {
      margin-top: 50px;

      h2 {
        margin-bottom: 20px;
      }

      #quick-fix-descr {
        margin-bottom: 15px;
      }

      .quick-fix {
        .descr {
          border-radius: 12px;
          background-color: #ffe2e2;
          height: auto;
          width: 97%;
          padding: 7px 0px;
          padding-left: 19px;
          cursor: pointer;
          margin-bottom: 15px;

          &:hover {
            background-color: #ffc2bf;
          }
        }

        #network-icon {
          font-size: 20px;
          margin-bottom: -4px;
          margin-right: 8px;
          color: #ad5039;
        }

        #coins-icon {
          font-size: 20px;
          margin-bottom: -4px;
          margin-right: 8px;
          color: #ad5039;
        }
      }
    }
  }

  #r {
    float: right;

    #phone-box-container {
      margin-top: 30px;
      padding: 26px 20px;
      padding-bottom: 20px;
      border: 1px solid #cfcfcf;
      border-radius: 8px;
      width: 400px;

      #h {
        font-weight: bold;
      }

      #d {
        margin-top: 12px;
        margin-bottom: 10px;
      }

      #phone-nr {
        margin-top: 10px;
        font-size: 18px;
        padding: 9px 15px 6px 10px;
        background-color: #e3e3e385;
        border-radius: 8px;
        width: fit-content;
      }

      #phone-descr {
        margin-bottom: 20px;
      }
    }

    #social-box-container {
      margin-top: 30px;
      padding: 26px 20px;
      padding-bottom: 20px;
      border: 1px solid #cfcfcf;
      border-radius: 8px;
      width: 400px;

      #h {
        font-weight: bold;
      }

      #d {
        margin-top: 12px;
        margin-bottom: 10px;
      }

      #platforms-container {
        display: grid;

        .social-media-platform {
          display: inline-flex;
          margin-top: 10px;
          cursor: pointer;

          .icon {
            font-size: 22px;
            margin-right: 8px;
            margin-top: 3px;
          }
          .name {
            cursor: pointer;
          }

          &:hover {
            color: #cd2328;
          }
        }

      }
    }

    #support-img {
      height: 430px;
      margin-top: 55px;
      margin-left: 66px;
    }

  }

  #faq-container {
    margin-top: 60px;

    #faq-h2 {
      margin-bottom: 25px;
    }

    #faq-filter {
      margin-bottom: 35px;

      #descr {
        font-weight: bold;
        margin-bottom: 20px;
      }

      #cats {
        display: flex;

        .cat, .cat-active {
          border-radius: 12px;
          background-color: #ffe2e2;
          height: auto;
          width: -moz-fit-content;
          width: fit-content;
          padding: 7px 10px;
          cursor: pointer;
          margin-right: 10px;
          border: 1px solid white;

          &-active, &:hover {
            background-color: #ffafab;
          }

          &-active {
            border: 1px solid #d26057;
          }
        }
      }

    }
  }

  #ig-icon {
    color: #d3076d;
  }

  #reddit-icon {
    color: #ff4900
  }

  #clearer {
    clear: both;
  }

  &-mobile {
    #email-box {
      display: block;
      margin-top: 30px;
      padding: 14px 20px;
      padding-right: 9px;
      border: 1px solid #b5b5b5;
      border-radius: 6px;

      #icon {
        font-size: 25px;
        margin-top: 0px;
        margin-left: 2px;
        margin-bottom: 5px;
      }

      #descr-container {
        margin-left: 4px;

        #h {
          font-weight: bold;
        }
        #descr {
          margin-top: 4px;
        }
      }
    }

    #phone-box-container {
      margin-top: 30px;
      padding: 26px 20px;
      padding-bottom: 20px;
      border: 1px solid #b5b5b5;
      border-radius: 8px;
      width: 88%;

      #h {
        font-weight: bold;
      }

      #d {
        margin-top: 12px;
        margin-bottom: 10px;
      }

      #phone-icon {
        margin-bottom: -2px;
      }

      #phone-nr {
        margin-top: 10px;
        font-size: 17px;
        padding: 9px 15px 6px 10px;
        background-color: #e3e3e3;
        border-radius: 8px;
        width: fit-content;
      }

      #phone-descr {
        margin-bottom: 20px;
      }
    }

    #social-box-container {
      margin-top: 30px;
      margin-bottom: 50px;
      padding: 14px 11px 20px 16px;
      border: 1px solid #b5b5b5;
      border-radius: 6px;
      width: 92%;

      #h {
        font-weight: bold;
      }

      #d {
        margin-top: 10px;
        margin-bottom: 20px;
        width: 95%;
      }

      #platforms-container {
        display: grid;

        .social-media-platform {
          display: inline-flex;
          margin-top: 10px;

          .icon {
            font-size: 22px;
            margin-right: 8px;
            margin-top: -1px;
          }
          .name {
            width: fit-content;
          }

          &:hover {
            color: #cd2328;
          }
        }

      }
    }

    #quick-fix-container {
      margin-top: 50px;
      margin-bottom: 80px;

      h2 {
        margin-bottom: 20px;
      }

      .quick-fix {
        .descr {
          font-size: 17px;
          background: linear-gradient(137deg, rgb(251 163 147) 0%, rgb(247 190 178) 100%);
          width: fit-content;
          border-radius: 8px;
          padding: 4px 10px;
          margin-bottom: 15px;
        }
        #network-icon {
          font-size: 17px;
          margin-bottom: -3px;
          margin-right: 7px;
          color: #ad5039;
        }

        #coins-icon {
          font-size: 17px;
          margin-bottom: -3px;
          margin-right: 7px;
          color: #ad5039;
        }
      }
    }

    #faq-container {
      #faq-filter {
        margin-bottom: 35px;

        #cats {
          display: block;
          margin-top: -5px;

          .cat, .cat-active {
            width: 95%;
            margin-right: 10px;
            margin-bottom: 8px;
          }
        }

      }
    }
  }

}
