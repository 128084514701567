#my-records-container, #my-records-container-mobile {
  padding-bottom: 100px;

  #content-spinner {
    margin-top: 45px;
  }

  #h2 {
    #count {
      margin-left: 6px;
      margin-right: 6px;
    }
  }

  #filter-text-input {
    margin-top: 30px;

    .text-input {
      width: 350px !important;
    }
  }

  #records-container {
    margin-top: 35px;
  }

  &-mobile {
    #filter-text-input {
      width: 100% !important;

      .text-input {
        width: 100% !important;
        font-size: 17px;
      }
    }
  }
}