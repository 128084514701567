#rr-checkout-container, #rr-checkout-container-mobile {
  width: 530px;
  margin-top: 30px;
  height: fit-content;
  background: linear-gradient(180deg, rgb(244 246 246 / 27%) 0%, rgba(245, 245, 245, 0.22) 41%);
  padding: 23px 28px;
  padding-bottom: 40px;
  background: linear-gradient(white, white) padding-box,linear-gradient(360deg, rgb(219 219 219) 0%, rgb(233 233 233) 100%) border-box;
  border-radius: 1em;
  border: 1px solid transparent;

  #checkout-h {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    background-image: linear-gradient(176deg, #000, #6c6c6c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  #checkout-descr {
    font-size: 17px;
  }

  .included-item {
    margin-bottom: 24px;

    #h {
      display: inline-flex;

      #title {
        font-weight: bold;
        font-size: 17px;
      }
    }
    #description {
      margin-top: 2px;
      font-size: 16px;
      line-height: 20px;
      margin-left: 34px;

      .note {
        font-size: 12px;
        color: #b3b3b3;
      }
    }
  }

  #support {
    margin-top: 35px;
    text-align: center;;

    #phone {
      font-size: 17px;
      color: #666666;

      #phone-nr {
        #phone-icon {
          color: grey;
          margin-bottom: -2px;
          margin-right: 3px;
        }
      }
    }
  }

  #whats-included-container {
    margin-top: 20px;

    #whats-included-h {
      font-size: 19px;
      margin-bottom: 18px;
    }

    #record-icon {
      color: #626262;
      font-size: 17px;
      margin-top: 4px;
      margin-left: 3px;
      margin-right: 10px;
    }

    #guarantee-icon {
      color: green;
      font-size: 23px;
      margin-right: 11px;
      margin-left: -1px;
      margin-top: 4px;
    }

    #auto-updates-icon {
      color: #363636;
      font-size: 23px;
      margin-right: 11px;
      margin-left: -1px;
      margin-top: 4px;
    }

    #recovery-system-icon {
      color: #363636;
      font-size: 21px;
      margin-top: 3px;
      margin-right: 11px;
      font-weight: 700;
      margin-left: 1px;
    }
  }

  #premium-features-container {
    margin-top: 45px;

    #header-container {
      display: inline-flex;
      #premium-features-h {
        font-size: 19px;
        margin-bottom: 18px;
      }
      #price-tag {
        margin-left: 10px;
        font-size: 17px;
        background-color: #f0ebf6;
        height: fit-content;
        padding: 0px 8px;
        border-radius: 5px;
        margin-top: 0px;
      }
    }

    #privacy-icon {
      color: #7a29a3;
      font-size: 18px;
      margin-top: 5px;
      margin-right: 14px;
    }

    #claim-protection-icon {
      color: #7a29a3;
      font-size: 20px;
      margin-top: 5px;
      margin-right: 11px;
    }

    #add-premium-cb {
      background: linear-gradient(137deg, rgb(244 232 253) 0%, rgb(238, 238, 238) 30%);
      margin-left: -2px;
      margin-top: 25px;
      width: 96%;
    }
  }

  #summary-container {
    margin-top: 35px;

    #summary-h {
      font-size: 18px;
      font-weight: bold;

      #shopping-cart-icon {
        margin-bottom: -2px;
        margin-right: 10px;
      }

      #currency-picker {
        display: inline-flex;
        margin-left: 228px;

        #usd {
          margin-right: 10px;
        }

        #usd, #eur {
          border: 1px solid #d9d9d9;
          padding: 0px 5px;
          font-size: 14px !important;
          font-weight: 300;
          border-radius: 7px;

          &:hover {
            cursor: pointer;
            border: 1px solid #bcbcbc;
          }
        }

        .picked {
          background-color: #ececec;
        }

      }
    }

    #summary-descr {
      margin-top: 8px;
      font-size: 17px;
      padding-left: 3px;
    }

    #order-items-container {
      margin-top: 30px;

      .order-item {
        background-color: whitesmoke;
        margin-bottom: 10px;
        padding-top: 7px;
        padding-left: 15px;
        padding-bottom: 7px;
        border-radius: 7px;

        .inline {
          display: inline-flex;

          #count {
            font-size: 15px;
            width: 25px;
            text-align: center;
            border-radius: 13px;
            height: 21px;
            padding-bottom: 5px;
          }

          #item-name {
            margin-left: 20px;
            width: 400px;
            font-size: 17px;
          }

          #item-amount {
            font-size: 17px;
            text-align: right;
            width: 54px;
          }
        }
      }

      #prem-item {
        margin-top: 8px;
      }

      #total-amount-container {
        width: 517px;
        text-align: right;
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 7px;

        #label {
          font-size: 17px;
          margin-right: 10px;
        }
        #amount {
          font-size: 17px;
          font-weight: bold;
        }
      }
    }

    #coupon-code-container {
      display: inline-flex;
      margin-top: -5px;
      margin-left: 2px;

      #coupon-input {
        width: 100px !important;
        min-width: 85px;
        height: 18px;
        font-size: 17px;
        padding-top: 10px;
      }

      #apply-coupon-button {
        min-width: 78px;
        margin-top: 58px;
        margin-left: -13px;
        height: 42px;
        font-size: 17px;
        padding-top: 10px;
      }
    }
  }

  #payment-form {
    margin-top: 30px;
    border-top: 1px solid #d5d5d5;
    padding-top: 40px;

    #payment-form-h {
      font-size: 18px;
      font-weight: bold;
    }

    #payment-descr {
      font-size: 17px;
      margin-top: 5px;
      line-height: 24px;
    }

    #name-container {
      display: inline-flex;
      margin-top: 8px;

      #first-name-input {
        font-size: 17px;
        width: 200px !important;
        margin-right: 33px;
      }

      #last-name-input {
        font-size: 17px;
        width: 268px !important;
      }
    }

    #email-input-container {
      margin-top: 0px;

      #email-input {
        font-size: 17px;
        width: 517px !important;
      }
    }

    #payment-methods {
      margin-top: 30px;

      #label {
        font-weight: bold;
        font-size: 17px;
        margin-bottom: 8px;
      }

      .single-method {
        display: inline-flex;
        background: white;
        width: 99% !important;
        padding-left: 12px !important;
        padding-top: 8px !important;;
        padding-bottom: 10px !important;
        margin-bottom: 13px !important;
        border-radius: 6px !important;;
        border: 1px solid #dddddd;
        cursor: pointer ;

        &:hover {
          border: 1px solid #b7b7b7;
        }

        .radio-set, .radio-unset {
          font-size: 22px;
          margin-top: 4px;
          margin-right: 9px;
          color: #383838;
        }

        .method-name {
          cursor: pointer;
        }
      }

      #cc-logo {
        width: 80px;
        margin-right: 23px;
        margin-top: 2px;
        height: 27px;
      }

      #cc-name {
        font-size: 17px;
        margin-top: 2px;
      }

      #paypal-logo {
        margin-right: 34px;
        height: 21px;
        margin-left: 3px;
        margin-top: 4px;
      }

      #paypal-name {
        margin-top: 2px;
        font-size: 17px;
      }

    }

    #checkboxes {
      margin-top: 20px;

      #get-updates-cb {
        background: linear-gradient(137deg, rgb(235, 235, 235) 0%, rgb(241 241 241) 81%);
        margin-left: -2px;
        margin-top: 20px;
        width: 517px;
      }

      #accept-tos-cb {
        background: linear-gradient(137deg, rgb(235, 235, 235) 0%, rgb(241 241 241) 81%);
        margin-left: -2px;
        margin-top: 12px;
        margin-bottom: 10px;
        width: 96%;
      }
    }

    #order-button-container {
      margin-top: 20px;

      #by-ordering-accept {
        font-size: 17px;
        text-align: center;

        a {
          font-size: 17px;
        }
      }

      #order-button {
        height: 45px;
        width: 539px;
        margin-top: 10px;
        margin-bottom: 15px;

        .text-icon {
          font-size: 19px !important;
          margin-bottom: -3px;
        }
      }

      .feedback {
        margin-top: -5px;
        width: 448px;
        p {
          text-align: center;
        }
      }
    }

    #spinner-container {
      #spinner {
        display: block;
        width: 23px !important;
        margin-top: 11px !important;
        margin: 0 auto;
      }

      #psp-info {
        #icon {
          margin-right: 6px;
          font-size: 18px;
          margin-bottom: -3px;
        }

        margin-top: 16px !important;
        margin: 0 auto;
        text-align: center;
        font-size: 16px;
        width: 80%;
        border: 1px solid #d9d9d9;
        padding: 12px 8px;
        border-radius: 15px;
        color: #626262;
      }
    }

    #connect-wallet-container {
      text-align: center;

      #connect-wallet-button {
        width: 479px;
        margin-top: 32px;
        margin-bottom: 15px;
        height: 44px;
        padding-top: 11px;

        &:hover {
          color: white;
        }
      }

      #why-text {
        font-size: 16px;
        margin-top: -3px;
       }

      #wallet-explainers {
        margin-top: 25px;
        margin-bottom: 30px;

        .explainer {
          cursor: pointer;
          width: 479px;
          border: 1px solid #c7c7c7;
          border-radius: 10px;
          padding-top: 6px;
          padding-bottom: 5px;
          margin-bottom: 15px;

          .inline {
            .arrow {
              cursor: pointer;
              color: #cd2328;
              font-size: 20px;
              margin-top: 3px;
            }

            .title {
              cursor: pointer;
              margin-left: 8px;
              font-size: 17px;
              color: #2e2e2e;
            }

          }

          .expanded-text {
            cursor: pointer;
            margin-top: 10px !important;
            font-size: 17px;
            line-height: 24px;
            width: 90%;
            margin: 0 auto;
            padding-bottom: 7px;

            a {
              font-size: 17px;
            }
          }

          &:hover {
            background: linear-gradient(322deg, rgb(253 253 253) 0%, rgb(245 245 245) 30%);
          }
        }
      }

      #getting-started-link {
        color: #cd2328;
        margin-top: 10px;
        font-size: 17px;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

    }

  }

  #order-error-container {
    border-radius: 10px;
    padding: 6px 8px;
    margin-top: 25px;
    width: 97%;
    cursor: pointer;
    background-color: #f7b3b3;
    text-align: center;

    #msg {
      font-size: 17px;
      color: #6f0303;
    }
  }

  #expand-container {
    text-align: center;
    margin-top: 35px;
    margin-bottom: 30px;

    #expand-button {
      font-size: 17px;
      padding-right: 16px;
      padding-left: 12px;
      height: 51px !important;
      width: 300px;

      .text-icon {
        margin-bottom: -3px;
      }
    }
  }

  &-mobile {
    width: 88%;
    margin-left: 0px;
    margin-top: 40px;
    padding: 23px 20px;

    #checkout-h {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    #checkout-descr {
      font-size: 17px;
    }

    .included-item {
      margin-bottom: 24px;

      #h {
        display: inline-flex;

        #title {
          font-weight: bold;
          font-size: 17px;
        }
      }

      #description {
        margin-top: 1px;
        line-height: 22px;
        margin-left: 34px;
      }
    }

    #footnotes {
      text-align: center;
      margin-top: 30px;

      p {
        font-size: 15px;
        color: #a7a7a7;
        margin-bottom: 1px;
        line-height: 20px;
      }
    }

    #support {
      margin-top: 50px;
      text-align: center;

      #phone {
        font-size: 17px;
        #phone-nr {
          #phone-icon {
            color: grey;
            margin-bottom: -2px;
            margin-right: 3px;
          }
        }
      }
    }

    #whats-included-container {
      margin-top: 20px;

      #whats-included-h {
        font-size: 18px;
        margin-bottom: 18px;
      }

      #record-icon {
        color: #626262;
        font-size: 17px;
        margin-top: 4px;
        margin-left: 3px;
        margin-right: 10px;
      }

      #guarantee-icon {
        font-size: 21px;
        margin-right: 13px;
        margin-left: 0px;
        margin-top: 2px;
      }

      #auto-updates-icon {
        font-size: 21px;
        margin: 2px 12px 8px 1px;
      }

      #recovery-system-icon {
        font-size: 20px;
        margin-top: 2px;
        margin-right: 10px;
        font-weight: 700;
        margin-left: 2px;
        margin-bottom: 9px;
      }
    }

    #premium-features-container {
      margin-top: 45px;

      #premium-features-h {
        font-size: 19px;
        margin-bottom: 18px;
      }

      #privacy-icon {
        font-size: 18px;
        margin: 3px 13px 9px 2px;
      }

      #claim-protection-icon {
        font-size: 20px;
        margin-top: 0px;
        margin-right: 11px;
        margin-left: 3px;
        margin-bottom: 10px;
      }

      #add-premium-cb {
        width: 95%;
        margin-left: -2px;
        margin-top: 15px;
        padding-top: 9px;
        padding-bottom: 11px;

        .cb-icon {
          font-size: 25px;
          margin-top: 0px;
          margin-right: 8px;
        }
      }
    }

    #summary-container {
      margin-top: 0px;
      padding-top: 10px;

      #summary-h {
        display: inline-flex;
        font-size: 18px;
        font-weight: bold;

        #shopping-cart-icon {
          margin-bottom: -2px;
          margin-right: 10px;
        }

        #currency-picker {
          margin-left: 15px;
          display: flex;
          margin-top: 2px;

          #usd, #eur {
            border: 1px solid #d9d9d9;
            padding: 0px 5px;
            font-size: 14px !important;
            font-weight: 300;
            border-radius: 7px;

            &:hover {
              cursor: pointer;
              border: 1px solid #bcbcbc;
            }
          }

          .picked {
            background-color: #ececec;
          }

        }
      }

      #summary-descr {
        width: 100%;
        margin-top: 20px;
        font-size: 17px;
      }

      #order-items-container {
        margin-top: 30px;

        .order-item {
          margin-bottom: 10px;
          padding-left: 15px;
          padding-top: 8px;
          padding-bottom: 8px;
          border-radius: 7px;
          width: 97%;

          #count {
            background-color: #d9d9d9;
            font-size: 15px !important;
            width: 30px !important;
            text-align: center !important;
            border-radius: 13px !important;
            height: 23px !important;
            padding-bottom: 3px !important;
            margin-top: -1px;
            padding-top: 1px;
          }

          #item-name {
            margin-left: 20px !important;
            width: 180px !important;
            font-size: 17px !important;
          }

          #item-amount {
            font-size: 17px;
            text-align: right;
            width: 54px;
          }
        }

        #prem-item {
          margin-top: 8px;
        }

        #total-amount-container {
          width: 300px;
          text-align: right;
          margin-top: 7px;

          #label {
            font-size: 17px;
            margin-right: 10px;
          }
          #amount {
            font-size: 17px;
            font-weight: bold;
          }
        }
      }

      #coupon-code-container {
        #apply-coupon-button {
          padding-top: 8px;
          height: 40px !important;
          margin-top: 55px;
        }
      }
    }

    #expand-container {

      #expand-button {
        width: 70%;
      }
    }

    #payment-form {
      margin-top: 45px;
      border-top: 1px solid #d5d5d5;
      padding-top: 35px;

      #payment-form-h {
        font-size: 18px;
        font-weight: bold;
      }

      #payment-descr {
        font-size: 17px;
        margin-top: 5px;
        line-height: 24px;
      }

      #name-container {
        display: block;
        margin-top: 8px;

        #first-name-input {
          font-size: 17px;
          width: 100% !important;
          margin-right: 0px;
        }

        #last-name-input {
          font-size: 17px;
          width: 100% !important;
          margin-right: 0px;
        }
      }

      #email-input-container {
        margin-top: 0px;

        #email-input {
          font-size: 17px;
          width: 100% !important;
          margin-right: 0px;
        }
      }

      #payment-methods {
        margin-top: 35px;

        .single-method {
          display: inline-flex;
          background: white;
          width: 96% !important;
          padding-left: 12px;
          padding-top: 8px;
          padding-bottom: 8px;
          margin-bottom: 13px;

          .radio-set, .radio-unset {
            font-size: 22px;
            margin-top: 4px;
            margin-right: 9px;
            color: #383838;
          }

          .method-name {
            cursor: pointer;
          }
        }

        #cc-logo {
          height: 25px;
          margin-right: 20px;
          width: 80px;
          margin-top: 3px;
        }

        #cc-name {
          margin-top: 3px;
        }

        #paypal-logo {
          margin-right: 30px;
          margin-left: 3px;
          margin-top: 4px;
        }

        #paypal-name {
          margin-top: 3px;
        }

      }

      #checkboxes {
        margin-top: 20px;

        #get-updates-cb {
          margin-left: -2px;
          margin-top: 5px;
          width: 94%;
          padding-top: 8px;
          padding-bottom: 8px;

          .cb-icon {
            font-size: 30px !important;
            margin-top: -2px !important;
          }
        }

        #accept-tos-cb {
          margin-left: -2px;
          margin-top: 15px;
          width: 92%;
          padding-top: 8px;
          padding-bottom: 8px;
        }
      }

      #order-button-container {
        .feedback {
          margin-top: -5px;
          min-width: 80%;
          width: 90%;
          padding-top: 8px;
          padding-bottom: 8px;
        }

        #order-button {
          font-size: 18px;
          width: 100% !important;
          height: 49px !important;
          margin-top: 40px;
          margin-bottom: 15px;

          .text-icon {
            font-size: 19px !important;
            margin-bottom: -3px;
          }
        }
      }

      #spinner {
        display: block;
        width: 23px !important;
        margin-top: 10px !important;
        margin: 0 auto;
      }

      #connect-wallet-container {
        text-align: center;

        #connect-wallet-button {
          width: 101% !important;
          margin-top: 30px;
          margin-bottom: 15px;
          height: 46px !important;
          padding-top: 11px;
          padding-bottom: 16px;
          margin-left: -5px !important;
          font-size: 17px !important;
        }

        #why-text {
          font-size: 16px;
          margin-top: -5px;
        }

        #wallet-explainers {
          margin-top: 50px;
          margin-bottom: 0px;

          .inline {
            display: inline-flex;

            .arrow {
              margin-top: 1px !important;
            }
          }

          .explainer {
            display: block;
            padding-left: 2px;
            padding-right: 0px;
            padding-top: 10px;
            padding-bottom: 8px;
            width: 99%;
          }
        }

        #getting-started-link {
          margin-top: 10px;
          font-size: 17px;
        }

      }

    }

    #order-error-container {
      border-radius: 10px;
      padding: 6px 8px;
      margin-top: 25px;
      width: 97%;
      cursor: pointer;
      background-color: #f7b3b3;
      text-align: center;

      #msg {
        font-size: 17px;
        color: #6f0303;
      }
    }
  }
}