.public-mode-extension-container, .public-mode-extension-container-mobile {
  margin-bottom: 120px;

  .l1 {
    .record-type {
      background: linear-gradient(63deg, rgb(213 129 235) 0%, rgb(227 144 247) 100%);
      height: 26px;
      margin-top: -1px;
    }

    .added-on-container {
      height: 24px;
      margin-top: -1px;
      margin-left: -2px;
      border-radius: 3px;
      padding: 5px 15px 5px 10px;
      border-left: none !important;
      border: 1px solid #eb81a9;

      .change-date {

        .cal-icon {
          margin-right: 7px;
          margin-bottom: -2px;
          color: #484848;
        }

        .data {
          font-family: monospace;
          margin-left: 12px;
        }
      }
    }
  }

  .l2 {
    margin-top: 35px;

    .originator {
      display: contents;
      width: fit-content;

      .data {
        margin-left: 15px !important;
      }
    }
  }

  .l3 {
    margin-top: 40px;

    .descr-container {
      width: fit-content;
      margin: 0 auto;

      .descr-label {
        font-size: 17px;
        color: #747474;
        font-weight: bold;
      }

      .descr-val {
        margin-top: 10px;
      }

    }
  }

  &-mobile {
    .record-content {
      .inline {
        display: inline-flex;
      }

      .l1 {
        .record-type {
          padding: 8px 15px 2px 9px !important;
        }

        .added-on-container {
          height: 22px;
          display: inline-flex;
          padding: 7px 15px 5px 10px;

          .change-date {
            display: inline-flex;

            .data {
              margin-left: 8px;
            }
          }
        }
      }

      .l2 {
        margin-top: 25px;

        .originator {
          font-size: 16px !important;

          .data {
            margin-top: -4px !important;
            margin-left: 8px !important;
            font-size: 16px !important;
            padding-left: 8px !important;
            padding-right: 8px !important;
          }
        }
      }

      .l3 {
        margin-top: 40px;

        .descr-container {

          .descr-label {
            margin-top: 15px;
            font-size: 15px;
          }

          .descr-val {
            margin-top: 9px;
            font-size: 16px;
            width: 100%;
          }
        }

      }

    }
  }

}