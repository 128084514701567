.logo-container {
  .logo {
    padding-top: 20px;
    width: 163px;
    cursor: pointer;
    margin-right: 8px;
  }

  #spinner-container {
    position: absolute;
    margin-top: -20px;
    margin-left: 171px;

    #spinner {
      width: 15px;
    }
  }
}