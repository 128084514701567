.branch-accepted-container, .branch-accepted-container-mobile {
  margin-bottom: 150px;

  .l1 {
    .record-type {
      background: linear-gradient(63deg, rgb(235 129 169) 0%, rgb(255 136 161) 100%);
      height: 26px;
      margin-top: -1px;
    }

    .added-on-container {
      height: 24px;
      margin-top: -1px;
      margin-left: -2px;
      border-radius: 3px;
      padding: 5px 15px 5px 10px;
      border-left: none !important;
      border: 1px solid #EB81A9FF;

      .change-date {
        display: inline-flex;

        .cal-icon {
          margin-right: 7px;
          margin-bottom: -2px;
          color: #484848;
        }

        .data {
          margin-left: 8px;
          font-family: monospace;
        }
      }
    }
  }

  .l2 {
    margin-top: 20px;

    .originator {
      display: inline-flex;
      font-size: 15px;
      width: fit-content;

      .data {
        margin-left: 15px !important;
      }
    }
  }

  .l3 {
    margin-top: 40px;

    .descr-container {
      width: fit-content;
      margin: 0 auto;

      .descr-label {
        font-size: 17px;
        color: #747474;
        font-weight: bold;
      }

      .descr-val {
        margin-bottom: 11px;
        margin-left: 3px;
        margin-right: 3px;
      }
    }
  }


  &-mobile {
    .record-content {
      .inline {
        display: inline-flex;
      }

      .l1 {
        .record-type {
          font-size: 16px !important;
          padding-top: 7px !important;
          padding-bottom: 1px !important;
        }

        .added-on-container {
          display: inline-flex;
          height: 20px;
          padding-top: 7px;

          .change-date {
            display: inline-flex;

            .data {
              margin-left: 8px !important;
            }
          }
        }
      }

      .l2 {
        margin-top: 25px;

        .originator {
          margin-top: -5px !important;
          font-size: 15px !important;
          margin-left: 1px;

          .data {
            margin-top: -4px !important;
            margin-left: 8px !important;
            font-size: 16px;
          }
        }
      }

      .l3 {
        margin-top: 40px;

        .descr-container {

          .descr-label {
            margin-top: 15px;
            font-size: 16px;
          }

          .descr-val {
            margin-top: 9px;
            font-size: 16px;
            width: 100%;
          }
        }

      }

    }
  }

}