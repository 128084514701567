.img-closed, .img-viewing, .img-closed-mobile, .img-viewing-mobile {
  display: block;
  margin-top: 10px;
  margin-right: 14px;
  font-size: 15px;
  cursor: pointer;
  padding: 12px 14px 8px;
  display: inline-flex;
  border: 1px solid #cfcfcf;
  border-radius: 12px;

  &:hover {
    border: 1px solid #b5240e !important;
  }

  .icon {
    font-size: 19px;
    margin-right: 8px;
    margin-top: 0px;
  }

  .img-nr {
    font-size: 17px;
    margin-top: -4px;
  }

  &-mobile {
    .img-nr {
      margin-top: -1px;
    }
  }
}

.img-viewing, .img-viewing-mobile {
  .icon {
    color: #d34141 !important
  }
}

.img-closed, .img-closed-mobile {
  .icon {
    color: #b6b6b6 !important;
  }
}

.img-open, .img-open-mobile {
  display: block;
  margin-top: 10px;

  .img {
    max-width: 470px;
    margin-top: 12px;
    border-radius: 5px;
    box-shadow: 0px 5px 9px #c7c7c7;
  }

  .close-info {
    font-size: 15px;
    cursor: pointer;
    margin-top: 17px;
    margin-bottom: -12px;
    margin-left: 1px;
    background: linear-gradient(90deg, rgba(173,173,173,1) 0%, rgba(255,255,255,0) 50%);
    padding: 6px 9px;
    padding-right: 0px;
    width: 98%;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &-mobile {
    .close-info {
      font-size: 14px;
      width: 98%;
    }
    .img {
      max-width: 80% !important;
    }
  }
}