#pending-ext-container, #pending-ext-container-mobile {
  margin-top: -20px;
  margin-bottom: 100px;
  background: linear-gradient(8deg, rgb(255 255 255 / 34%) 0%, rgb(245 245 245 / 54%) 100%);
  height: fit-content;
  padding-top: 22px;
  padding-bottom: 25px;
  border-radius: 20px;
  border: 1px solid #cc6552;

  #h-container {
    display: inline-flex;
    width: 100%;
    justify-content: center;

    #icon {
      font-size: 22px;
      margin-top: 6px;
      margin-right: 8px;
      color: #c7462d;
    }

    #h {
      display: inline-flex;
      font-size: 20px;
      margin-top: 4px;
      margin-bottom: 10px;

      #count {
        margin-left: 6px;
        margin-right: 6px;
        font-weight: bold;
      }
    }
  }

  #items {
    margin-top: 20px;
    text-align: center;

    .item {
      display: inline-flex;
      margin-bottom: 15px;
      border: 1px solid #d1d1d1;
      padding: 10px 25px 10px 60px;
      border-radius: 15px;
      width: 900px;

      p {
        cursor: pointer;
      }

      .label {
        text-align: left;
        font-weight: bold;
        margin-right: 10px;
      }

      #type-label {
        width: 105px;
      }

      #cap-type {
        text-align: left;
        width: 125px;
      }

      #happened-label {
        text-align: left;
        width: 115px;
      }

      #happened-on {
        text-align: left;
        width: 140px;
      }

      #provided-by-label {
        text-align: left;
        width: 170px;
      }

      &:hover {
        background-color: #ebebeb;
        cursor: pointer;
      }

    }
  }

  #creation-hint, #order-text {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    color: #4f4f4f;
    text-align: center;
  }

  &-mobile {
    #h-container {
      display: block;
      width: 100%;
      text-align: center;

      #icon {
        margin-bottom: 5px;
        font-size: 25px;
      }

      #h {
        display: block;
      }
    }

    #creation-hint {
      margin-top: 30px;
      text-align: center;
    }

    #items {
      margin-top: 30px;

      .item {
        width: 94%;
        display: block;
        margin: 0 auto;
        margin-bottom: 15px;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 15px;

        #type-label, #happened-label, #provided-by-label {
          width: auto;
        }

        #cap-type, #happened-on, #provider-name {
          width: auto;
          text-align: center;
          margin-bottom: 10px;
        }

        .label {
          text-align: center !important;
        }
      }
    }
  }
}