#trx-receipt-container {
  justify-content: center;
  display: flex;
}

.order-container, .order-container-mobile {
  margin-top: 30px;
  padding-left: 4px;

  h1 {
    text-align: center;
  }

  #descr {
    margin-bottom: 40px;
  }

  .form-steps-container {
    margin: 0 auto;
    width: fit-content;
    margin-top: 30px !important;
    text-align: center;
  }

  #shopping-cart-container {
    width: 522px;
    margin: 0 auto;
    border: 1px solid #d5d5d5;
    border-radius: 15px;
    margin-top: 40px;
    padding: 10px 30px;

    #icon {
      color: #b91616;
      font-size: 25px;
      margin-top: 13px;
    }

    #contents {
      margin-left: 10px;

      #quantity {
        display: flex;
        justify-content: center;
        font-size: 14px;
        background-color: #e7e7e7;
        width: 24px;
        height: 25px;
        border-radius: 16px;
        margin-top: 13px;
        vertical-align: 6px;
        align-items: center;
        margin-right: 30px;
      }

      #product {
        font-size: 17px;
        width: 220px;
      }

      #vr-descr {
        font-size: 17px;
        color: grey;
        margin-top: -5px;
      }
    }

    #price {
      font-size: 17px;
      margin-top: 10px;
      margin-left: 168px;
    }
  }

  #form-container {
    display: block;
    width: fit-content;
    margin: 0 auto;
    margin-top: 20px !important;
    padding-bottom: 80px;

    #details-form-container {
      width: 530px;
      padding: 8px 28px;
      padding-bottom: 40px;
      background: linear-gradient(white, white) padding-box,linear-gradient(360deg, rgb(219 219 219) 0%, rgb(233 233 233) 100%) border-box;
      border-radius: 1em;
      border: 1px solid transparent;

      #details-h {
        font-size: 20px;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 15px;
      }

      .hint-container {
        width: 506px !important;
      }

      #origination-dropdown {
        display: none;
      }

      #product-name-inputs-container {
        #product-name-input {
          margin-top: 12px;
          width: 100% !important;
        }

        .hint-container {
          margin-top: 10px;
        }
      }

      #product-descr-inputs-container {
        margin-top: 40px;

        #product-descr-input {
          margin-top: 30px;

          .text-area  {
            margin-top: 12px;
            width: 505px !important;
          }

          .hint-container {
            margin-top: 10px;
          }

        }
      }

      #purchase-date-input-container {
        margin-top: 40px;

        .purchase-date-input {
          width: 504px !important;
          font-size: 18px !important;
        }

        #purchase-date-validation {
          margin-top: 15px;
        }
      }

      #product-uid-input-container {
        margin-top: 40px;

        #product-uid-input {
          margin-top: 12px;
          width: 100% !important;
        }

        .hint-container {
          margin-top: 10px;
        }
      }

      #image-upload-container {
        margin-top: 30px;
        width: 100% !important;

        .input-label {
          margin-bottom: 4px;
        }

        #product-imgs-hints-mobile {
          margin-top: 14px;
          margin-bottom: 11px;
        }

        .img-input-container {
          .img-preview-container {
            .img-preview {
              width: 146px;
              height: 146px;
              border-radius: 10px;
            }

            .icon-container {
              width: 130px;
            }
          }
          .input-error {
            width: 500px;
          }
        }
      }

      #continue-details-button {
        width: 100%;
        margin-top: 30px;
      }

      #submit-error {
        width: 504px;
        margin-top: 8px;
        text-align: center;
      }
    }

    #options-container {
      width: 530px;
      padding: 12px 28px;
      padding-bottom: 40px;
      background: linear-gradient(white, white) padding-box,linear-gradient(360deg, rgb(219 219 219) 0%, rgb(233 233 233) 100%) border-box;
      border-radius: 1em;
      border: 1px solid transparent;

      #options-h {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      #checkout-descr {
        font-size: 17px;
      }

      .included-item {
        margin-bottom: 24px;

        #h {
          display: inline-flex;

          #title {
            font-weight: bold;
            font-size: 17px;
          }
        }
        #description {
          margin-top: 2px;
          font-size: 16px;
          line-height: 20px;
          margin-left: 34px;

          .note {
            font-size: 12px;
            color: #b3b3b3;
          }
        }
      }

      #whats-included-h {
        font-size: 19px;
        margin-bottom: 18px;
      }

      #record-icon {
        color: #626262;
        font-size: 17px;
        margin-top: 4px;
        margin-left: 3px;
        margin-right: 10px;
      }

      #guarantee-icon {
        color: green;
        font-size: 23px;
        margin-right: 11px;
        margin-left: -1px;
        margin-top: 4px;
      }

      #auto-updates-icon {
        color: #363636;
        font-size: 23px;
        margin-right: 11px;
        margin-left: -1px;
        margin-top: 4px;
      }

      #recovery-system-icon {
        color: #363636;
        font-size: 21px;
        margin-top: 3px;
        margin-right: 11px;
        font-weight: 700;
        margin-left: 1px;
      }

      #continue-payment-button {
        width: 100%;
        margin-top: 50px;
      }

      #step-back-text {
        margin-top: 20px;
        text-align: center;
        color: grey;
        cursor: pointer;
        font-size: 16px;

        &:hover {
          text-decoration: underline;
          color: black;
        }
      }


      #footnotes {
        text-align: center;
        margin-top: 30px;

        p {
          font-size: 15px;
          color: #a7a7a7;
          margin-bottom: 1px;
          line-height: 20px;
        }
      }
    }

    #premium-features-container {
      margin-top: 45px;

      #header-container {
        display: inline-flex;
        #premium-features-h {
          font-size: 19px;
          margin-bottom: 18px;
        }
        #price-tag {
          margin-left: 10px;
          font-size: 17px;
          background-color: #f0ebf6;
          height: fit-content;
          padding: 0px 8px;
          border-radius: 5px;
          margin-top: 0px;
        }
      }

      #privacy-icon {
        color: #7a29a3;
        font-size: 18px;
        margin-top: 5px;
        margin-right: 14px;
      }

      #claim-protection-icon {
        color: #7a29a3;
        font-size: 20px;
        margin-top: 5px;
        margin-right: 11px;
      }

      #add-premium-cb {
        background: linear-gradient(137deg, rgb(244 232 253) 0%, rgb(238, 238, 238) 30%);
        margin-left: -2px;
        margin-top: 15px;
        width: 96%;
      }
    }

  }

  &-mobile {
    padding-left: 4px;
    margin-bottom: 60px;

    .form-steps-container-mobile {
      margin-top: 25px;
      text-align: center;

      .steps {
        .step {
          .nr {
            display: none;
          }
        }
      }
    }

    #shopping-cart-container {
      width: 87%;
      padding-left: 20px;
      padding-right: 20px;

      .inline {
        display: inline-flex;
      }

      #icon {
        margin-top: 11px;
      }

      #quantity {
        display: none !important;
      }

      #contents {
        margin-left: 20px;
        margin-top: 3px;
      }


      #price {
        margin-left: -11px;
        margin-top: 12px;
      }
    }

    #descr {
      margin-bottom: 40px;
    }

    #form-container {
      margin-top: 30px;
      display: block;
      padding-bottom: 20px;

      #details-form-container {
        width: 88%;
        padding-left: 20px;
        padding-right: 20px;

        #details-h {
          padding-left: 2px;
        }

        #origination-dropdown {
          display: none;
        }

        .hint-container-mobile {
          margin-top: 10px;
          width: 93% !important;
          padding-right: 13px;

          .hint .text {
            font-size: 17px !important;
            line-height: 25px;
          }
        }

        #product-name-inputs-container {
          margin-top: 20px;

          #product-name-input {
            margin-top: 12px;
            width: 100% !important;
            padding-top: 9px !important;
            margin-right: -3px;
          }

        }

        #product-descr-inputs-container {
          margin-top: 30px;

          #product-descr-input {
            margin-top: 30px;

            .text-area  {
              margin-top: 12px;
              width: 94% !important;
            }
          }
        }

        #purchase-date-input-container {
          margin-top: 40px;

          .purchase-date-input {
            width: 94% !important;
            font-size: 18px !important;
          }
        }

        #product-uid-input-container {
          margin-top: 40px;

          #product-uid-input {
            margin-top: 12px;
            margin-right: -3px;
            width: 100% !important;
          }

          .hint-container {
            margin-top: 10px;
          }
        }

        #image-upload-container {
          margin-top: 40px;
          width: 100% !important;

          .input-label {
            margin-bottom: 4px;
          }

          #product-imgs-hints-mobile {
            margin-top: 14px;
            margin-bottom: 11px;
          }

          .img-input-container {
            .input-error {
              width: 92%;
              .msg {
                font-size: 17px;
              }
            }

            .img-preview-container {
              .img-preview {
                width: 146px;
                height: 146px;
                border-radius: 10px;
              }

              .icon-container {
                width: 130px;
              }

            }
          }
        }

        #continue-details-button {
          height: 51px !important;
          width: 100% !important;
        }

        #submit-error {
          width: 92%;
          .msg {
            font-size: 17px;
          }
        }
      }

      #options-container {
        width: 88%;
        padding-left: 20px;
        padding-right: 20px;

        #continue-payment-button {
          height: 51px !important;
          width: 100% !important;
        }
      }

      #sidebar-explainer {
        margin-top: 25px;
      }
    }
  }

}
