#login-container {
  text-align: center;
  min-height: 600px;
}

.content-container, .content-container-mobile {
  h1 {
    width: auto;
  }

  #spinner {
    width: 30px;
    margin-top: 20px;
  }


  #email-form {
    margin-top: 40px;
    display: inline-block;

    .input-error {
      margin: 0 auto;
      margin-top: 10px !important;
    }

    .label-container {
      margin: 0 auto;
    }

    .hint-container {
      margin-top: 10px;
      margin-bottom: 4px;
      text-align: left;
    }

    #email-input {
      width: 100% !important;
    }

    #send-email-button {
      width: 300px;
      height: 49px;
    }
  }

  #verify-form {
    width: 500px;
    margin-top: 50px;
    display: inline-block;

    .input-error {
      margin: 0 auto;
      margin-top: 10px !important;
    }

    .label-container {
      margin: 0 auto;
    }

    #verify-descr {
      margin-bottom: 20px;
    }

    #code-input {
      width: 100% !important;
      text-align: center;
    }

    #send-code-button {
      width: 300px;
      height: 45px;
    }

    #go-back-link {
      font-size: 17px;
      margin-top: 20px;
      color: grey;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: black;
      }
    }
  }

  &-mobile {

    #email-form {
      margin-top: 30px;

      .hint-container-mobile {
        margin-top: 10px;
        margin-bottom: 5px;
      }

      #send-email-button {
        height: 47px !important;
        margin-top: 10px;
      }
    }

    #verify-form {
      width: 100%;

      #send-code-button {
        height: 49px !important;
        margin-top: 10px;
      }
    }
  }
}