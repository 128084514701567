#vr-section-cotainer, #vr-section-cotainer-mobile {
  position: relative;
  padding-top: 90px;
  padding-bottom: 60px;
  background: linear-gradient(305deg, rgb(2, 2, 2) 5%, rgb(30, 30, 30) 33%, rgb(30, 30, 30) 90%);

  #lights-anim {
    position: absolute;
    z-index: 0;
    clip-path: inset(0px 1px);
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0%;
    left: 0%;
  }

  #content {
    z-index: 1;
    margin: 0 auto;
    text-align: center;
    color: white;

    .hl {
      color: #d34c32;
      margin-left: 8px;
      margin-right: 8px;
    }

    #headline {
      font-family: "BS", "Arial", sans-serif;
      transform: scaleY(0.9);
      letter-spacing: -1px;
      font-size: 45px;
    }

    #descr {
      position: relative;
      z-index: 1;
      margin-top: 30px !important;
      font-size: 21px;
      line-height: 28px;
      width: 850px;
      margin: 0 auto;
    }

    #sub-content {
      position: relative;
      z-index: 1;
      display: inline-flex;
      margin-top: 80px;

      #l {
        width: 600px !important;
        margin-right: 110px;

        #bp-container {
          text-align: left;
          margin-top: 30px;

          .bp-item {
            display: inline-flex;

            .bullet {
              width: 15px;
              height: 14px;
              background: linear-gradient(310deg, rgb(203, 72, 47) 30%, rgb(189 54 85) 100%);
              margin-right: 13px;
              margin-top: 7px;
              border-radius: 3px;
            }
            .bp {
              margin-bottom: 15px;
              width: 100%;
            }
          }

          #bp2 {
            margin-bottom: 15px;
          }

          #updates-context-container {
            margin-top: -3px;
            margin-bottom: 30px;
            margin-left: 20px;

            #update-types-container {
              margin-top: -3px;
              margin-bottom: 40px;
              margin-left: 8px;

              #label {
                font-size: 16px;
                margin-bottom: 4px;
                color: #d15546;
              }

              .ut {
                background: linear-gradient(340deg, rgb(46, 46, 46) 30%, rgb(34 34 34) 100%);
                padding: 2px 11px;
                width: fit-content;
                border-radius: 6px;
                font-size: 17px;
                margin-right: 10px;
              }
            }
          }

        }

        #footnotes {
          margin-top: 40px;
          margin-left: 20px;
          text-align: left;

          p {
            font-size: 16px;
            color: #626262;
            margin-bottom: 1px;
            line-height: 20px;
          }
        }
      }

      #r {


        #record-presentation {
          #img-container {
            #record-img {
              height: 210px;
              margin-top: -20px;
              position: relative;
              margin-bottom: 20px;
            }
          }

          #name {
            font-size: 24px;
          }

          #cat {
            font-size: 17px;
            color: grey;
          }
        }

        #price-box {
          #price {
            margin-top: 10px;
            font-size: 20px;
            margin-bottom: 10px;
          }

          #guarantees {
            margin-top: 35px;

            .guarantee {
              background: linear-gradient(292deg, rgb(40 40 40) 0%, rgb(48 34 34) 81%);
              padding: 5px 13px;
              width: -moz-fit-content;
              width: 342px;
              border-radius: 6px;
              margin-bottom: 12px;
              font-size: 17px;
            }

            #shield-icon {
              margin-right: 12px;
              margin-bottom: -2px;
              color: #0bcb0b;
            }

            #speed-icon {
              margin-right: 12px;
              margin-bottom: -2px;
            }
          }
        }

        #order-button {
          margin-top: 30px;
          width: 371px;
          padding-top: 11px;
          box-shadow: rgb(56 56 56 / 51%) -2px 0px 8px 0px;
          background: linear-gradient(354deg, rgb(34 34 34) 0%, rgb(49, 49, 49));

          &:hover, &:focus {
            background: linear-gradient(354deg, rgb(34 34 34) 0%, rgb(33, 33, 33));
            color: white;
          }
        }

        #payment-methods {
          margin-top: 10px;
          text-align: center;

          #cc {
            width: 75px;
            margin-right: 20px;
          }
          #paypal {
            width: 85px;
          }
        }

        #support-infos-container {
          margin-top: 22px;

          #hotline {
            font-size: 17px;
            margin-top: 14px;
            text-align: center;
            color: #a5a5a5;

            #icon {
              margin-bottom: -2px;
            }
          }
        }

      }
    }

  }

  &-mobile {
    padding-top: 50px;
    padding-bottom: 50px;

    #lights-anim {
      top: 0%;
      width: 130%;
      height: 100%;
      left: -13%;
      object-fit: cover;
    }

    #content {
      width: 98%;
      margin: 0 auto;
      text-align: center;
      color: white;

      .hl {
        color: #d34c32;
      }

      #headline {
        font-family: "BS", "Arial", sans-serif;
        transform: scaleY(0.9);
        letter-spacing: -1px;
        font-size: 34px;
        line-height: 35px;
      }

      #descr {
        margin-top: 40px !important;
        font-size: 18px;
        line-height: 25px;
        width: 95%;
        margin: 0 auto;
      }

      #sub-content {
        margin-top: 70px;
        display: flex;
        flex-direction: column-reverse;

        #l {
          margin: 0 auto;
          margin-top: 30px;
          width: 93% !important;

          #bp-container {
            text-align: left;
            margin-top: 30px;

            .bp-item {
              display: inline-flex;

              .bullet {
                width: 15px;
                height: 14px;
                margin-right: 13px;
                margin-top: 7px;
              }
              .bp {
                line-height: 25px;
                margin-bottom: 15px;
                width: 100%;
              }
            }

            #updates-context-container {
              margin-top: -3px;
              margin-bottom: 30px;
              margin-left: 20px;

              #update-types-container {
                margin-top: -3px;
                margin-bottom: 40px;
                margin-left: 8px;

                #label {
                  font-size: 16px;
                  line-height: 24px;
                  margin-bottom: 8px;
                  color: #d15546;
                }

                .inline {
                  display: block;

                  .ut {
                    padding: 4px 11px;
                    width: auto;
                    font-size: 17px;
                    margin-bottom: 10px;
                  }
                }
              }
            }

          }

          #footnotes {
            margin-top: 40px;
            margin-left: 20px;
            text-align: left;

            p {
              font-size: 16px;
              margin-bottom: 1px;
              line-height: 20px;
            }
          }
        }

        #r {
          margin-top: 20px;

          #record-presentation {
            #img-container {
              #record-img {
                height: 130px;
                margin-top: -20px;
                position: relative;
                margin-bottom: 20px;
              }
            }

            #name {
              font-size: 24px;
            }

            #cat {
              font-size: 17px;
              color: grey;
            }
          }

          #price-box {
            #price {
              margin-top: 10px;
              font-size: 20px;
              margin-bottom: 10px;
            }

            #guarantees {
              margin-top: 35px !important;
              margin: 0 auto;
              display: block;

              .guarantee {
                margin: 0 auto;
                padding: 7px 13px;
                width: 87%;
                margin-bottom: 12px !important;
                font-size: 17px;
              }

            }
          }

          #order-button {
            margin-top: 23px;
            width: 94% !important;
            height: 47px !important;
            font-size: 18px;
            padding-top: 10px;
          }

          #payment-methods {
            margin-top: 20px;
            text-align: center;

            #cc {
              width: 75px;
              margin-right: 20px;
            }
            #paypal {
              width: 85px;
            }
          }

          #support-infos-container {
            margin-top: 10px;

            #hotline {
              font-size: 17px;
              margin-top: 14px;
              text-align: center;
              color: #a5a5a5;

              #icon {
                margin-bottom: -2px;
              }
            }
          }

        }
      }

    }
  }
}