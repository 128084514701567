.user-button, .user-button-passive, .user-button-mobile {
    height: 44px;
    min-width: 150px;
    margin-top: 15px;
    margin-bottom: 3px;
    padding: 12px 10px;
    border: 1px solid #959595;
    border-radius: 10px;
    color: white;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 18px;
    cursor: pointer;
    background: linear-gradient(15deg, rgb(68 68 68) 0%, rgb(54 54 54) 50%);
    box-shadow: rgba(211, 211, 211, 0.5098039216) -3px 3px 8px 0px;

    &-passive {
        color: black;
        background: linear-gradient(15deg, rgb(255, 255, 255) 0%, rgb(249, 249, 249) 50%);
    }

    &:hover {
        cursor: pointer;
        background: linear-gradient(15deg, rgb(26, 26, 26) 0%, rgb(28, 28, 28) 50%);
        box-shadow: 0px 0px 1px 1px rgb(71 71 71 / 23%);
    }

    &:active {
        color: white;
        background-color: #e1583e;
    }

    &:disabled {
        background-color: #cccccc;
        border: 1px solid #b2b2b2;
        color: grey;
        cursor: not-allowed;
    }

    &-mobile {
        width: 100% !important;
        height: 33px !important;
    }
}

.disabled {
    margin-top: 0px;
    background-color: gray;
    border-color: gray;

    &:hover {
        background-color: gray;
        border-color: gray;
    }
}
