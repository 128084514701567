.img-input-container {

  .inline-mobile {
    display: inline-flex !important;
    flex-wrap: wrap;
  }

  .img-upload-input {
    display: none;
  }

  #reset-img-button {
    margin-top: 8px;
    margin-left: 12px;
    height: 38px;
  }

  .input-label-img {
    width: 67px;
    height: 37px;
    padding-top: 14px;
    text-align: center;
    margin-right: 18px;
    margin-top: 8px;
    margin-bottom: 6px;
    border: 1px solid #cfcfcf !important;
    background: linear-gradient(155deg, rgb(245 245 245) 0%, rgb(239, 246, 247) 100%);
    border-radius: 10px;
    font-size: 14px;

    .icon {
      font-size: 20px;
      color: #343434;
    }

    &:hover {
      cursor: pointer;
      border: 1px solid #b5240e !important;
      background-color: #e8e8e8de;
    }

    &:focus {
      background-color: #e8e8e8de;
      border: 1px solid #b5240e !important;
    }
  }

  .img-preview-container {
    display: grid;

    .img-preview {
      width: 90px;
      height: 83px;
      margin-right: 18px;
      margin-top: 9px;
      margin-bottom: 6px;
      border: 1px solid #9096a4 !important;
      border-radius: 3px;
      object-fit: cover;

      &:hover {
        cursor: pointer;
        border: 1px solid #b5240e !important;
      }
    }

    .icon-container {
      text-align: center;
      width: 90px;
      margin-bottom: 15px;

      .close-icon {
        font-size: 18px;
        cursor: pointer;

        &:hover {
          color: #cd2328;
        }
      }
    }
  }

  .input-error {
    margin-bottom: 30px;
    width: 100%;
  }

}
