#newsletter-section-container, #newsletter-section-container-mobile {
  padding-top: 80px;
  padding-bottom: 50px;

  #content {
    width: 1220px;
    margin: 0 auto;
    text-align: center;

    #vr-seal-golden {
      width: 200px;
      margin-bottom: 30px;
    }

    #header {
      font-family: "BS", "Arial", sans-serif;
      transform: scaleY(0.9);
      font-size: 27px;
      letter-spacing: -1px;
    }

    #descr {
      margin-top: 15px !important;
      margin: 0 auto;
      font-size: 19px;
      line-height: 28px;
      width: 800px;
    }

    .inline {
      margin-top: -2px;

      #email-input {
        margin-top: 0px;
        width: 340px !important;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: none;
        font-size: 17px;
        padding-top: 12px;
        border-color: #b7b7b7 !important;
      }

      #subscribe-button {
        font-size: 17px;
        box-shadow: none !important;
        margin-top: 29px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        margin-left: -5px;
        height: 44px;
        min-width: 128px;
        border: 1px solid #b7b7b7;
        border-left: none;
        background: linear-gradient(4deg, rgb(183, 66, 44) 0%, rgb(215 69 60) 100%);

        &:hover {
          background: linear-gradient(4deg, rgb(171, 58, 37) 0%, rgb(170 40 32) 100%);
        }
      }
    }

    .feedback {
      margin: 0 auto;
    }

  }

  &-mobile {
    padding-top: 50px;

    #content {
      width: 100%;
      margin: 0 auto;

      #header {
        font-family: "BS", "Arial", sans-serif;
        transform: scaleY(0.9);
        font-size: 24px;
        letter-spacing: -1px;
      }

      #descr {
        margin-top: 15px !important;
        margin: 0 auto;
        font-size: 18px;
        line-height: 28px;
        width: 97%;
      }

      .inline {
        margin-top: -2px;

        #email-input {
          margin-top: 0px;
          width: 90% !important;
          padding-top: 12px;
        }

        #subscribe-button {
          font-size: 17px;
          margin-top: 29px;
          margin-left: -8px;
          height: 46px !important;
        }
      }

    }
  }
}