#my-account-container, #my-account-container-mobile {
  width: 610px;
  padding-bottom: 130px;

  #account-descr {
    margin-top: 10px;
  }

  #display-name-form {
    margin-top: 50px;

    #current-display-name {
      background-color: #dbdbdb;
      width: fit-content;
      padding: 4px 9px;
      border-radius: 8px;

      #icon {
        margin-right: 7px;
        margin-bottom: -2px;
      }
    }

    #display-name-descr {
      margin-top: 10px;
    }

    #display-name-input {
      width: 300px !important;
    }

    #save-name-button {
      margin-top: 59px;
      height: 45px;
      margin-left: 15px;
    }

    .feedback {
      margin-top: 5px !important;
    }
  }

  #logout-form {
    margin-top: 40px;

    #logout-descr {
      margin-top: 10px;
    }

    #logout-button {
      height: 45px;
    }
  }

  &-mobile {
    width: 93%;

    #display-name-form {
      margin-top: 30px;

      #display-name-input {
        width: 91% !important;
      }

      #save-name-button {
        margin-top: -4px;
        margin-left: 0px;
        width: 98% !important;
        height: 48px !important;
      }

      .feedback {
        min-width: 89% !important;
        margin-top: 10px !important;
      }
    }

    #logout-form {
      #logout-button {
        margin-left: -1px;
        margin-top: 20px;
        width: 98% !important;
        height: 48px !important;
      }
    }

  }
}