.record-privacy-switcher-container, .record-privacy-switcher-container-mobile {
  margin-top: -5px;
  margin-bottom: 50px;

  #l {
    float: left;

    h1 {
      margin-bottom: 9px;
      font-size: 22px;
    }

    .privacy-info-container {
      #private-mode-hint {
        margin-top: 12px;
        width: 540px;
      }

      #public-mode-hint {
        margin-top: 12px;
        width: 540px;
      }
    }

    .slider-container {
      margin-top: 50px;


      #month-count {
        margin-bottom: 19px;
        margin-top: 25px;
        padding-left: 1px;
        font-weight: bold;
      }

      #slider-wrapper {
        border: 1px solid #bbbbbb;
        padding: 26px 35px 22px 23px;
        width: fit-content;
        border-radius: 3px;
        height: 37px;
      }

      #slider-hint {
        margin-top: 12px;
        width: 540px;
      }

      .rc-slider {
        width: 495px;
        margin-left: 7px;

        .rc-slider-rail {
          background-color: #adadad;
        }

        .rc-slider-track {
          background-color: #d34c32;
        }

        .rc-slider-dot-active {
          border-color: #d34c32;
        }

        .rc-slider-handle {
          border: solid 2px #d34c32;
          opacity: 1;
          width: 18px;
          height: 18px;
          margin-top: -7px;}

        .rc-slider-mark-text {
          font-size: 16px;
          margin-top: 4px;
        }
      }
    }

    .submit-container {
      margin-top: 60px;

      #submit-button {
        margin-top: 20px;
        width: 422px;
      }

      #spinner {
        width: 20px;
        margin-left: 15px;
        margin-top: 30px;
      }
    }
  }

  #r {
    float: right;
  }

  #clearer {
    clear: both;
  }


  #trx-receipt-container {
    justify-content: center;
    display: flex;
  }

  #balance-info {
    background: linear-gradient(164deg, rgba(205,35,40,1) 0%, rgb(215, 82, 87) 75%);
    padding: 7px 10px;
    width: fit-content;
    border-radius: 3px;
    p {
      color: white;
      font-size: 14px;
    }
  }

  &-mobile {
    .slider-container {
      .rc-slider {
        width: 100% !important;
        margin-left: 0px;
      }

      #slider-wrapper {
        padding: 4px 8px;
        border: none;
        width: 95% !important;
      }
    }

    .submit-container {
      width: 100% !important;

      #submit-button {
        width: 100% !important;
        height: auto;
        font-size: 14px;
      }

      #spinner {
        margin-left: 47%;
        margin-top: 15px;
        margin-bottom: -15px;
      }

    }
  }
}