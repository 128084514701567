#footer, #footer-mobile {
  padding-top: 60px;
  margin-bottom: -9px;
  padding-bottom: 50px;
  background: linear-gradient(305deg, rgb(2, 2, 2) 5%, rgb(26, 26, 26) 33%, rgb(30, 30, 30) 90%);

  #content {
    display: grid;
    color: #d1cece;
    margin: 0 auto;
    width: fit-content;

    #logo {
      width: 170px;
      margin: 0 auto;
    }

    #infos {
      display: inline-flex;
      margin-top: 45px;

      #infobox-company {
        margin-right: 70px;
      }

      #infobox-support {

      }

      .infobox {
        .name {
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 8px;
        }

        .link {
          margin-right: 15px;
          font-size: 17px;
        }

        #phone {
          display: inline-flex;
          font-size: 17px;
          margin-left: 3px;
          line-height: unset;

          #icon {
            margin-left: 8px;
            margin-right: 5px;
          }
        }
      }
    }

    #back-to-top-container {
      margin-top: 50px !important;
      margin: 0 auto;
      text-align: center;

      #icon {
        height: 24px;
        width: 26px;
        background: linear-gradient(4deg, rgb(183 66 44) 0%, rgb(170 40 32) 100%);
        border-radius: 25px;
        padding: 8px;
        cursor: pointer;

        &:hover {
          background: linear-gradient(4deg, rgb(171, 58, 37) 0%, rgb(170 40 32) 100%);
        }
      }


    }
  }

  &-mobile {
    padding-top: 50px;

    #content {
      display: grid;
      color: #d1cece;
      margin: 0 auto;
      width: fit-content;

      #logo {
        width: 150px;
        margin: 0 auto;
      }

      #infos {
        display: block;
        margin-top: 35px;
        text-align: center;

        #infobox-company {
          margin-right: 0px;
          margin-bottom: 30px;
        }

        #infobox-support {

        }

        .infobox {
          .name {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 8px;
          }

          .link {
            margin-right: 15px;
            font-size: 17px;
          }

          #phone {
            display: inline-flex;
            font-size: 17px;
            margin-left: 3px;
            line-height: unset;

            #icon {
              margin-left: 8px;
              margin-right: 5px;
            }
          }
        }
      }

      #back-to-top-container {
        margin-top: 50px !important;
        margin: 0 auto;
        text-align: center;

        #icon {
          height: 24px;
          width: 26px;
          border-radius: 25px;
          padding: 8px;

        }


      }
    }
  }
}