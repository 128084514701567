.find-stolen-container, .find-stolen-container-mobile {
  background: linear-gradient(305deg, #020202 5%, #1e1e1e 33%, #1e1e1e 90%);
  color: #fff;
  margin-top: 30px;
  padding-top: 90px;
  padding-bottom: 60px;

  .hl {
    color: #d34c32;
  }

  #content {
    width: 1220px;
    margin: 0 auto;
    text-align: center;

    #headline {
      font-family: "BS", "Arial", sans-serif;
      transform: scaleY(0.9);
      font-size: 40px;
      letter-spacing: -1px;
      word-spacing: 2px;
    }

    #explainer-diagram {
      width: 950px;
      margin-top: 50px;
    }

    #descr {
      margin-top: 50px !important;
      font-size: 20px;
      line-height: 28px;
      width: 950px;
      margin: 0 auto;
    }

    #see-on-yt {
      margin-top: 40px !important;
      border: 1px solid #ff7c7c;
      width: fit-content;
      margin: 0 auto;
      padding: 8px 18px;
      border-radius: 18px;
      cursor: pointer;

      &:hover {
        background-color: #ffeeee21;
      }

      #icon {
        color: red;
        margin-bottom: -4px;
        font-size: 22px;
        margin-right: 5px;
      }
    }

  }

  &-mobile {
    padding-top: 50px;
    padding-bottom: 70px;
    margin-top: 5px !important;

    #content {
      width: 95%;
      margin: 0 auto;
      text-align: center;

      #headline {
        font-size: 33px;
        line-height: 35px;
      }

      #explainer-diagram {
        width: 100%;
        margin-top: 30px;
      }

      #descr {
        margin-top: 30px !important;
        font-size: 18px;
        line-height: 26px;
        width: 96%;
        margin: 0 auto;
      }

      #see-on-yt {
        width: 80%;
      }
    }

  }

}