#provider-container, #provider-container-mobile {
  margin-bottom: 40px;

  #message-container {
    margin-top: 30px;

    h2 {
      font-size: 33px;
      font-weight: bold;
    }

    h3 {
      margin-top: 12px;
      font-size: 24px;
    }

    #vr-on-phone-img {
      width: 97%;
      margin-top: 40px;
      border-radius: 15px;
    }
  }

  #provider-contact-container {
    margin-top: 50px;

    #phone-box-container {
      margin-top: 30px;
      margin-right: 50px;
      padding: 26px 20px;
      padding-bottom: 20px;
      border: 1px solid #cfcfcf;
      border-radius: 8px;
      width: 560px;

      #h {
        font-weight: bold;
      }

      #d {
        margin-top: 12px;
        margin-bottom: 10px;
      }

      #phone-icon {
        margin-bottom: -2px;
      }

      #phone-nr {
        margin-top: 10px;
        font-size: 18px;
        padding: 9px 15px 6px 10px;
        background-color: #e3e3e385;
        border-radius: 10px;
        width: fit-content;
      }

      #phone-descr {
        margin-bottom: 20px;
      }
    }

    #email-box {
      display: inline-flex;
      width: 560px;
      margin-top: 30px;
      padding: 20px 20px;
      cursor: pointer;
      border: 1px solid #cfcfcf;
      border-radius: 10px;

      &:hover {
        background-color: #fbfbfb;
      }

      #icon {
        font-size: 25px;
        margin-top: 0px;
        margin-right: 12px;
        margin-bottom: 5px;
      }

      #descr-container {
        margin-left: 4px;
        cursor: pointer;

        #h {
          font-weight: bold;
        }
        #descr {
          margin-top: 10px;
        }
      }
    }
  }

  #resources-container {
    margin-top: 50px;

    #resource-items {
      margin-top: 30px;
      display: grid;

      .res-item {
        cursor: pointer;
        display: inline-flex;
        margin-bottom: 10px;
        border: 1px solid #d3d3d3;
        width: 450px;
        padding: 8px 20px;
        border-radius: 10px;

        .pdf-icon {
          color: #cd3e1c;
          font-size: 24px;
        }

        .yt-icon {
          color: #ef542f;
          font-size: 24px;
          margin-left: -2px;
          margin-right: 2px;
        }

        .resource-name {
          cursor: pointer;
          margin-left: 15px;
        }

        &:hover {
          background-color: #f1f1f1;
        }
      }
    }
  }

  &-mobile {
    #message-container {
      margin-top: 5px;

      h2 {
        font-size: 28px !important;
      }

      h3 {
        margin-top: 15px;
        font-size: 21px !important;
      }

      #vr-on-phone-img {
        width: 98%;
      }
    }

    #provider-contact-container {
      margin-top: 30px;

      #phone-box-container {
        width: 88%;
      }

      #email-box {
        width: 88%;
      }
    }

    #resources-container {
      #resource-items {
        .res-item {
          width: 88%;

          .pdf-icon {
            margin-top: 10px;
          }
        }
      }
    }
  }
}