.extension-create-container {
  margin-top: -5px;
  padding-left: 4px;

  #l, #l-mobile {
    float: left;

    h1 {

    }

    #h2-update {
      font-size: 24px;
      width: 520px;
    }

    #form {
      margin-top: 35px;
      width: 480px;

      #type-inputs-container {
        #ext-type-hint-container {
          margin-top: 12px;
          margin-bottom: 6px;
          width: 465px;
        }
      }

      #descr-inputs-container {
        margin-top: 45px;
        #change-descr-input {
          .text-area  {
            font-size: 18px !important;
          }
        }

        #change-descr-hints {
          margin-top: 12px;
          margin-bottom: 6px;
          width: 466px;
        }
      }

      #change-date-input-container {
        margin-top: 45px;

        .purchase-date-input {
          width: 465px;
          font-size: 17px !important;
        }
      }

      #image-upload-container {
        width: 100% !important;
        margin-top: 45px;
        margin-bottom: 13px;

        .input-label {
          margin-bottom: 2px;
        }

        .hint-container {
          margin-top: 12px;
          margin-bottom: 10px;
          width: 465px;
        }
      }
    }

    &-mobile {
      float: none;
      width: 100%;

      #h2-update {
        width: auto;
      }

      #form {
        width: 100%;
      }

      h1 {
        font-size: 25px !important;
        display: block;
        margin: 0 auto;
      }

      h2 {
        font-size: 18px !important;
        margin-top: 3px;
        text-align: center;
        line-height: 28px;
      }

      #change-type-dropdown {
        .dropdown {
          width: 100% !important;
        }
      }

      #descr-inputs-container {
        margin-top: 25px !important;

        #change-descr-input {
          .text-area {
            width: 94% !important;
          }
        }
      }

      #change-date-input-container {
        margin-top: 25px !important;

        .purchase-date-input {
          width: 94% !important;
        }
      }

      .vtr-calc-container-mobile {
        width: 94% !important;
      }

      #image-upload-container {

        .input-label {
          margin-bottom: 20px !important;
        }

        .img-input-container {
          .input-label-img {
            display: inline-block;
          }
        }
      }

    }

  }

  #r, #r-mobile {
    float: right;
    margin-top: 80px !important;

    &-mobile {
      float: none;

      .side-bar-explainer-container-mobile {
        width: 286px;
      }
    }
  }

  #trx-receipt-container {
    justify-content: center;
    display: flex;
    margin: 0 auto !important;
    width: 100%;
  }

  #clearer {
    clear: both;
  }

}

#submit-container, #submit-container-mobile {
  clear: both;

  #submit-button {
    margin-top: 50px;
    width: 490px;
  }

  #spinner {
    width: 23px;
    margin-left: 15px;
    margin-top: 60px;
  }

  &-mobile {
    margin-bottom: 50px;

    #submit-button {
      width: 100% !important;
      height: 47px !important;
    }

    #spinner {
      width: 20px;
      margin-left: 168px;
      margin-top: 15px;
    }
  }
}