#section-divider, #section-divider-mobile {

  #content {
    margin: 0 auto;
    width: fit-content;

    #elements {
      display: inline-flex;

      #dot-left {
        color: #d34620;
        font-size: 8px;
      }

      #dot-right {
        color: #d34620;
        font-size: 8px;
      }

      #line {
        border-top: 1px solid #dddddd;
        width: 1200px;
        margin-top: 3px;
      }
    }
  }

  &-mobile {
    width: 100%;

    #content {
      margin: 0 auto;
      width: 100%;

      #elements {
        justify-content: center;
        width: 100%;

        #line {
          width: 90%;
        }
      }
    }
  }
}