#ownership-change-container, #ownership-change-container-mobile {
  margin-top: -5px;

  #h1 {
  }

  &-mobile {
    margin-top: 35px;
    h1 {
      font-size: 25px !important;
    }

    #descr {
      font-size: 18px !important;
      margin-top: 0px;
    }

  }

}

#trx-receipt-container {
  justify-content: center;
  display: grid;


}

#form-container, #form-container-mobile {
  display: inline-flex;

  #transfer-opening-form, #transfer-opening-form-mobile {
    width: 650px;
    margin-top: 10px;

    #next-owner-input {
      width: 556px !important;
      font-family: monospace;
    }

    #date-container {
      margin-top: 25px;

      #change-date-input {
        width: 548px !important;
        font-size: 17px !important;
      }
    }

    #comment-input-container {
      margin-top: 25px;

      #comment-input {
        .text-area  {
          width: 549px !important;
        }
      }
      .hint-container {
        margin-top: 12px;
        margin-bottom: 10px;
      }
    }

    #submit-container {
      margin-top: 50px;

      #submit-button {
        width: 422px;
      }
    }

    &-mobile {
      margin-top: 20px;
      width: 100%;

      #next-owner-input {
        width: 100% !important;
      }

      #date-container #change-date-input {
        width: 94% !important;
      }

      #comment-input-container {
        .label-container {
          margin-bottom: 20px;
        }

        #comment-input .text-area {
          width: 94% !important;
        }

        .hint-container-mobile {
          width: 94% !important;
        }
      }

      #submit-container {
        width: 100%;

        #submit-button {
          width: 100% !important;
          height: 44px !important;
        }

        #spinner {
          margin-left: 165px;
          margin-top: 15px;
          margin-bottom: -15px;
        }
      }
    }

  }

  &-mobile {
    display: block;
    width: 100%;
    padding-right: 35px;

    #sidebar-explainer {
      width: 96%;
      margin-left: 0px !important;
      padding: 8px 10px;
      min-width: 0 !important;
      margin-top: 70px;
    }
  }

  #sidebar-explainer {
    margin-left: 220px;
  }
}

#transfer-confirmation-form, #transfer-confirmation-form-mobile {
  width: 96%;
  background: linear-gradient(159deg, rgb(236 236 236) 0%, rgb(243 243 243) 100%);
  border-radius: 3px;
  text-align: center;

  h2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .descr {
    max-width: 100% !important;
  }

  #appointed-hint-container {
    margin-top: 15px !important;
    margin-bottom: 40px !important;
    margin: 0 auto;
  }

  #new-role-ul {
    li {
      color: #d92c0c;
    }
  }

  #optional-comment {
    margin-top: 35px;
    max-width: 100% !important;
    text-align: center;
    display: inline-block;

    #label {
      font-size: 17px;
      color: #747474;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .red-quotes {
      color: #cd2328;
      font-size: 17px;
      font-weight: bold;
    }

    #quote {
      display: block;
      padding: 0px 3px;
    }
  }

  #submit-container {
    margin-top: 40px;
    margin-bottom: 10px;

    .vtr-calc-container {
      margin: 0 auto;
    }

    #confirm-transfer-button {
      width: 422px;
      margin-bottom: 10px;
    }

    #spinner {
      margin-top: 25px !important;
      position: absolute;
    }
  }

  &-mobile {
    width: 95%;
    padding: 12px 12px !important;

    h2 {
      font-size: 18px !important;
    }

    #descr {
      font-size: 14px !important;
    }

    .data {
      font-size: 14px;
    }

    #optional-comment {

    }

    .hint-container {
      width: 92%;
    }

    .vtr-calc-container-mobile {
      width: 268px;
    }

    #submit-container {
      #confirm-transfer-button {
        height: 34px;
        width: 99%;
      }

      #spinner-container {
        text-align: center;
        width: 390px;
        margin-top: 20px;

        #spinner {
          margin-top: 3px;
        }
      }
    }
  }

}