.content-container {
  margin-top: 35px !important;
}

.landing-container, .landing-container-mobile {
  margin-bottom: 30px;

  #wrong-net {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #5c3411;

    #content {
      background: linear-gradient(63deg, rgb(255 211 52) 0%, rgb(239 218 66) 100%);
      border-radius: 9px;
      padding: 12px 49px;

      #icon {
        color: #dd0000;
        font-size: 18px;
        margin-bottom: -3px;
        margin-right: 9px;
      }
    }
  }

  &-mobile {
    width: 100%;
    overflow-x: hidden;

    .ext-count {
      .label {
        font-size: 16px;
      }

      .val {
        font-size: 17px;
        margin-left: 84px !important;
        margin-top: -3px !important;
      }
    }

    #wrong-net {
      #content {
        padding: 10px 20px !important;
        font-size: 15px !important;
        line-height: 18px;
        text-align: center;

        #icon {
          font-size: 18px !important;
          margin-bottom: -3px !important;
          margin-right: 9px !important;
        }
      }
    }
  }

}