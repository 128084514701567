.transfer-confirmation-container, .transfer-confirmation-container-mobile {
  margin-bottom: 150px;

  .l1 {
    .record-type {
      background: linear-gradient(63deg, rgb(64, 211, 0) 0%, rgb(11 189 30) 100%);
      height: 26px;
      margin-top: -1px;
    }

    .added-on-container {
      height: 24px;
      margin-top: -1px;
      margin-left: -4px;
      border-radius: 5px;
      padding: 6px 15px 8px 12px;
      border-left: none !important;
      border: 1px solid #65bb41;

      .change-date {
        .cal-icon {
          margin-right: 7px;
          margin-bottom: -2px;
          color: #484848;
        }

        .data {
          margin-left: 8px;
          font-family: monospace;
        }
      }
    }

  }

  .l2 {
    margin-top: 30px;

    .originator {
      margin-top: 15px;
      display: inline-flex;

      .label {
        width: 100px;
      }

      .data {
        margin-left: 15px !important;
      }
    }
  }


  &-mobile {
    .record-content {

      .inline {
        display: inline-flex;
      }

      .l1 {
        .record-type {
          width: fit-content;
          padding: 8px 15px 2px 9px !important;
        }

        .added-on-container {
          height: 22px;
          display: inline-flex;
          padding: 7px 15px 5px 10px;


          .change-date {
            display: inline-flex;

            .data {
              font-family: monospace;
              margin-left: 8px;
            }
          }
        }
      }

      .l2 {
        margin-top: 25px;

        .originator {
          margin-top: -5px !important;
          margin-left: 1px;

          .label {
            width: 90px !important;
          }

          .data {
            margin-top: -4px !important;
            margin-left: 8px !important;
            font-size: 16px !important;
          }
        }
      }

    }
  }

}