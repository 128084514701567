.created-record-item {
  margin-bottom: 25px;
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  padding: 15px 45px 32px;
  background: linear-gradient(355deg, rgb(241 241 241) 0%, rgb(239, 239, 239) 100%);

  .text-icon {
    color: black !important;
  }

  .inline {
    //margin-top: 0px !important;
  }

  .status-infos {
    display: inline-flex;
    margin-top: 15px;
    margin-bottom: 24px !important;

    .transferred, .transferred-out {
      font-size: 17px;
      background: linear-gradient(151deg, rgb(142 227 101) 0%, rgb(131 231 120) 100%);
      width: fit-content;
      border-radius: 3px;
      padding: 6px 17px 5px 16px;
      margin-right: 10px;

      .text-icon {
        color: #294415 !important;
      }
    }

    .transferred-out {
      background: linear-gradient(151deg, rgb(101, 227, 156) 0%, rgb(120, 231, 198) 100%);
    }
  }

  .quick-info-bar {
    margin-top: 12px;
    margin-bottom: 4px !important;

    .added-on, .added-on-mobile {
      font-size: 17px;
      margin-right: 50px;
      margin-bottom: 8px;
      cursor: pointer;

      .cal-icon {
        color: #af4a36;
        margin-right: 11px;
        margin-bottom: -2px;
        font-size: 16px;
      }
    }

    .is-private, .is-private-mobile {
      margin-right: 50px;
      font-size: 17px;

      .public-icon, .private-icon {
        color: #af4a36;
        margin-right: 10px;
        margin-bottom: -2px;
        font-size: 16px;
      }

      &-mobile {
        font-size: 15px !important;
      }
    }

    .extension-count, .extension-count-mobile {
      font-size: 17px;

      .ext-icon {
        color: #af4a36;
        margin-right: 10px;
        margin-bottom: -2px;
        font-size: 16px;
      }

      &-mobile {
        margin-top: 7px;
        .text-icon {
          margin-bottom: -2px;
        }

        font-size: 15px !important;
      }
    }
  }

  .name {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 14px;
    cursor: pointer;
    color: #a91f1f;
    margin-top: 9px;
    width: fit-content;
  }


  .branch-status {
    background: linear-gradient(63deg, rgb(235 129 169) 0%, rgb(255 136 161) 100%);
    width: fit-content;
    border-radius: 3px;
    padding: 6px 17px 5px 16px;

    p {
      font-size: 17px;
    }
  }

  .descr-label {
    width: fit-content;
    margin-top: 9px;
    font-size: 17px;
    color: #606060;
    font-weight: bold;
  }

  .descr, .descr-mobile {
    width: fit-content;
    border-left: #bb3c3c 5px solid;
    border-radius: 3px;
    padding: 6px 8px;
    margin-top: 12px;
    margin-bottom: 11px;
    cursor: pointer;

  }

  .serial-nr-label {
    width: fit-content;
    margin-top: 25px;
    font-size: 17px;
    color: #606060;
    font-weight: bold;
  }

  .serial-nr, .serial-nr-mobile {
    margin-top: 3px;
    width: fit-content;
    cursor: pointer;

    .barcode-icon {
      color: #7c2825;
      margin-right: 10px;
      margin-bottom: -4px;
      font-size: 20px;
    }

    &-mobile {
      font-size: 15px;

      .text-icon {
        color: black !important;
      }
    }
  }

  .inline {
    cursor: pointer;
    display: flex;
  }


  &:hover {
    border-color: #d34c32;
    cursor: pointer;
  }
}