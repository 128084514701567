.environment-package, .environment-package-mobile {
  background: linear-gradient(305deg, rgb(2, 2, 2) 5%, rgb(30, 30, 30) 33%, rgb(30, 30, 30) 90%);
  padding-top: 70px;
  padding-bottom: 80px;


  #content {
    margin: 0 auto;
    text-align: center;
    color: white;

    #headline {
      font-family: "BS", "Arial", sans-serif;
      transform: scaleY(0.9);
      letter-spacing: -1px;
      font-size: 37px;
      word-spacing: 2px;
      width: 90%;
      margin: 0 auto;
      line-height: 34px;
    }

    #planet-img {
      width: 280px;
      margin-top: 44px;
    }

    #descr {
      margin-top: 40px !important;
      font-size: 19px;
      line-height: 28px;
      width: 800px;
      margin: 0 auto;
    }

  }

  &-mobile {

    #content {
      margin: 0 auto;
      text-align: center;

      #headline {
        font-size: 30px;
        line-height: 35px;
      }

      #descr {
        margin-top: 40px !important;
        font-size: 18px;
        line-height: 27px;
        width: 94%;
        margin: 0 auto;
      }

      #planet-img {
        width: 220px;
        margin-top: 44px;
      }

    }
  }
}