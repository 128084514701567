// HTML basics -----------------------

@font-face {
    font-family: "BS";
    src: url("../../public/fonts/bs.TTF") format('truetype');
    font-weight: normal;
    font-style: normal;
}

html, #root {
    //height: 100%;
}

body {
    height: 100%;
    min-width: 100%;
    background-color: white;
    font-family: Arial;
    margin-left: 0px !important;
    margin-top: 0px !important;
}

p {
    font-size: 18px;
    margin: 0px;
    line-height: 28px;
    cursor: default;
}

h1 {
    font-family: "BS", "Arial", sans-serif;
    transform: scaleY(0.9);
    width: fit-content;
    margin-bottom: 12px;
    font-size: 31px;
    letter-spacing: -1px;
    word-spacing: 3px;

    background-image: linear-gradient(90deg, #bd4a34, #c7462d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


h2 {
    font-family: "BS", "Arial", sans-serif;
    transform: scaleY(0.9);
    letter-spacing: -1px;
    font-size: 26px;
    color: #1e1e1e;
    clear: both;
    margin-top: 25px;
    margin-bottom: 6px;
}

h3 {
    font-size: 20px;
    color: #1e1e1e;
    clear: both;
    margin-top: 0px;
    margin-bottom: 8px;
}

a {
    font-size: 18px;
    color: #cd2328;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

// Design basics -----------------------

.content-container {
    margin: auto;
    margin-top: 60px !important;
    width: 1297px;

    &-mobile {
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 18px;

        h1 {
            font-size: 30px !important;
            letter-spacing: -1px;
            word-spacing: 2px;
            font-weight: normal;
        }

        h2 {
            font-size: 24px !important;
            letter-spacing: -1px;
            line-height: 30px;
        }

        p {
            line-height: 24px;
            font-size: 17px;
        }
    }
}


.inline {
    display: inline-flex;
}

.inline-mobile {
    display: block;
}

.underline {
    text-decoration: underline;
}

.vr-hl {

}

.vtr-hl {
    font-size: 14px;
    letter-spacing: 1px;
    color: #000000;
    border-radius: 4px;
    margin-left: 1px;
    margin-right: 1px;
    padding: 2px 4px;
    background: linear-gradient(83deg, rgb(255 185 58 / 46%) 0%, rgb(255 205 0 / 60%) 100%);
}

.text-icon {
    margin-right: 7px;
    margin-bottom: -2px;
    font-size: 16px;
}

.label-icon {
    margin-bottom: -2px;
    font-size: 15px;
    margin-right: 7px;
}

.input-label {
    font-size: 16px;
    color: #000000;
    width: fit-content;
    padding-left: 2px;
    border-radius: 7px;
    font-weight: bold;
}

.input-error {
    width: fit-content;
    cursor: default;
    margin-top: 3px;
    margin-bottom: 4px;
    border: 1px solid #f5adad;
    border-radius: 8px;
    padding: 6px 12px 4px 12px;
    background-color: #ffeded;
    color: #c12828;

    .msg {
        font-size: 17px;
    }
}

.purchase-date-input, .purchase-date-input-mobile {
    margin-top: 8px;
    border: 1px solid #949494 !important;
    padding: 13px 11px 12px 10px;
    background: linear-gradient(155deg, rgb(245 245 245) 0%, rgb(239, 246, 247) 100%);
    border-radius: 10px;

    &:hover {
        border: 1px solid #ad3d2c !important;
        background-color: #e8e8e8de;
    }

    &:focus {
        background-color: #e8e8e8de;
        border: 1px solid #ad3d2c !important;
    }
}

.react-datepicker__current-month {
    margin-bottom: 12px;
}

.react-datepicker__day-names {
    margin-top: 6px;
}

.react-datepicker__day--selected {
    background-color: #ad3d2c;
}