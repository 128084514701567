.info-tooltip-wrapper {

  .info-tooltip-container {
    float: right;
    margin-top: -19px;
    margin-right: -7px;

    #icon {
      color: #c7c7c7;
      font-size: 20px;

      &:hover {
        color: #8d8d8d;
      }
    }

    #text {
      position: absolute;
      margin-left: -3%;
      margin-right: 20px;
      background: linear-gradient(357deg, rgb(228 229 93 / 84%) 0%, rgb(227, 229, 131) 100%);
      font-size: 16px;
      padding: 5px 11px;
      border-radius: 8px;
      margin-top: 5px;
      box-shadow: 0px 3px 7px 2px #7a7a7a6b;
    }
  }

  #closer {
    clear: both;
  }
}