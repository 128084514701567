.transfer-opening-container, .transfer-opening-container-mobile {
  margin-bottom: 150px;

  .l1 {
    .record-type {
      background: linear-gradient(63deg, rgb(64, 211, 0) 0%, rgb(11 189 30) 100%);
      height: 26px;
      margin-top: -1px;
    }

    .added-on-container {
      height: 24px;
      margin-top: -1px;
      margin-left: -3px;
      border-radius: 5px;
      padding: 6px 15px 8px 13px;
      border-left: none !important;
      border: 1px solid #65bb41;

      .change-date {
        .cal-icon {
          margin-right: 7px;
          margin-bottom: -2px;
          color: #484848;
        }

        .data {
          margin-left: 8px;
          font-family: monospace;
        }
      }
    }
  }

  .l2 {
    margin-top: 30px;

    .record-creation-date {
      display: contents;
      width: fit-content;

      .data {
        margin-left: 15px !important;
      }
    }
  }

  .l3 {
    margin-top: 30px;
    display: inline-block;
    width: 400px;

    .originator {
      margin-top: 15px;
      display: inline-flex;

      .label {
        width: 180px;
        text-align: right;
        margin-top: 3px;
        margin-right: 2px;
      }

      .data {
        margin-left: 15px !important;
      }
    }

    .next-owner-address {
      margin-top: 18px;
      display: inline-flex;

      .label {
        width: 180px;
        text-align: right;
        margin-top: 3px;
        margin-right: 2px;
      }

      .data {
        margin-left: 15px !important;
        font-family: monospace;
        background-color: #dbdbdb;
        padding: 5px 12px;
        border-radius: 5px;
      }
    }
  }

  .l4 {
    .descr-container {
      width: fit-content;
      margin: 0 auto;

      .descr-label {
        margin-top: 15px;
        font-size: 15px;
        color: #747474;
        font-weight: bold;
      }
      .descr-val {
        margin-top: 13px;
      }

    }
  }

  .opening-hint {
    margin-top: 30px;
    border: 1px solid #dbdbdb;
    padding: 12px 15px;
    width: 640px;
    text-align: left;
    display: inline-flex;
    border-radius: 12px;

    #icon {
      color: grey;
      margin-right: 15px;
      font-size: 46px;
      margin-top: -10px;
    }

    p {
      font-size: 17px;
      line-height: 22px;
      color: #434343;
    }
  }

  &-mobile {
    .record-content {
      .inline {
        display: inline-flex;
      }

      .l1 {
        .record-type {
          width: fit-content;
          padding: 6px 15px 4px 9px !important;
        }

        .added-on-container {
          display: inline-flex;
          font-size: 17px;
          height: 22px;
          padding: 7px 15px 5px 10px;
          margin-left: -5px;

          .change-date {
            display: inline-flex;

            .data {
              margin-left: 8px;
            }
          }
        }
      }

      .l2 {
        margin-top: 30px;

        .record-creation-date {
          margin: 0 auto !important;
          display: block;

          .label {
            font-size: 18px;
          }

          .data {
            margin-top: 7px;
            padding: 8px 10px 5px 4px !important;
            margin-left: 8px !important;
          }
        }

        .originator {
          margin-top: -5px !important;
          font-size: 16px !important;
          margin-left: 1px;

          .data {

          }
        }
      }

      .l3 {
        width: 100%;
        margin-top: 30px;

        .originator {
          .label {
            width: 90px !important;
          }
          .data {
            font-size: 16px !important;
          }
        }

        .next-owner-address {
          display: block;

          .label {
            font-size: 18px;
            margin: 0 auto;
          }
          .data {
            margin-top: 8px;
            font-size: 17px !important;
            display: inline-block;
            width: fit-content;
          }
        }

      }

      .opening-hint {
        width: 90%;
        #icon {
          margin-top: -11px;
          width: 75px;
        }
      }


    }
  }

}