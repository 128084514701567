#order-success-container, #order-success-container-mobile {
  #order-h {
    background-image: linear-gradient(90deg, #30a649, #5fae3b, #22ac2a);
  }

  #sparkles {
    height: 80px;
    margin-left: 15px;
    margin-top: -3px;
    margin-bottom: -3px;
  }

  #order-descr {
    font-size: 21px;
  }

  #record-prp-link {
    font-size: 17px;
    width: fit-content;
    padding: 2px 5px;
    padding-right: 12px;
    padding-top: 4px;
    border-radius: 6px;
    margin-top: -4px;
    margin-left: 26px;
    margin-bottom: 30px;
    background: linear-gradient(54deg, rgb(230, 230, 230) 0%, rgb(229 229 229) 88%);

    #icon {
      margin-right: 10px;
      margin-bottom: -3px;
      margin-left: 1px;
    }

    &:hover {
      cursor: pointer;
      background: linear-gradient(54deg, rgb(215 215 215) 0%, rgb(223 212 212) 88%);
    }
  }

  #next-steps-container {
    margin-top: 20px;

    #next-steps-h {
      font-weight: bold;
      margin-top: 40px;
      margin-bottom: 20px;
      font-size: 20px;
    }

    #ordered-item, #process-item {
      margin-bottom: 0px;
    }

    #next-steps-items {
      display: grid;

      .item {
        font-size: 18px;
        margin-bottom: 20px;

        .full-check-icon {
          margin-top: 1px;
          color: green;
          margin-right: 10px;
        }

        .empty-check-icon {
          margin-top: 1px;
          color: green;
          margin-right: 10px;
        }

      }
    }

    #process-steps {
      display: grid;
      margin-top: 0px;
      margin-bottom: 30px;
      margin-left: 28px;

      .step {
        .icon {
          margin-right: 13px;
          margin-top: 3px;
          font-weight: bold;
          border-radius: 5px;
          color: green;
        }
        .text {
          width: 570px;
          margin-bottom: 15px;
          font-size: 17px;
          line-height: 23px;
        }
        #matic {
          background: linear-gradient(83deg, rgb(206 58 255 / 46%) 0%, rgb(255 0 245 / 60%) 100%);
          padding-bottom: 3px;
        }
      }

    }
  }

  #my-records {
    margin-top: 15px;
    font-size: 18px;
  }

  #r {
    margin-left: 220px;

    #support {
      margin-top: 30px;

      #support-h {
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 20px;
      }

      .q-item {
        margin-bottom: 20px;
        .h {
          font-size: 17px;
          font-weight: bold;
          margin-bottom: 5px;
        }
      }

      #phone-nr {
        background-color: #dddddd;
        width: fit-content;
        padding: 2px 4px;
        padding-left: 6px;
        padding-right: 12px;
        border-radius: 5px;

        #phone-icon {
          margin-right: 3px;
          margin-bottom: -1px;
          font-size: 17px;
          color: #4a4a4a;
        }
      }
      #phone-descr {
        font-size: 16px;
        margin-top: 6px;
        color: #878787;
      }

    }
  }


  &-mobile {
    text-align: center;

    #order-h {
      width: 100%;
      font-size: 33px !important;
    }

    #sparkles {
      height: 70px;
      margin-bottom: 20px;
    }

    #order-descr {
      font-size: 19px;
    }

    #l {
      float: none;
      width: 100%;
      padding-bottom: 20px;

      #next-steps-container {
        display: block;

        #next-steps-h {
        }

        #next-steps-items {
          display: block;

          .item {
            width: 100%;
            margin-bottom: 20px;

            .full-check-icon {
              margin-bottom: -3px;
              margin-right: 7px;
              font-size: 19px;
            }

            .empty-check-icon {
              margin-bottom: -3px;
              margin-right: 7px;
              font-size: 19px;
            }
          }

          #record-prp-link {
            margin-left: 0px;
            padding: 6px 10px;
            margin-top: 10px;
            font-size: 18px;
            padding-top: 9px;
          }

          #process-item {
            margin-top: 10px !important;
            margin-bottom: 0px !important;
          }
        }
      }

      #process-steps {
        display: grid;
        margin-top: 5px;
        margin-bottom: 30px;
        margin-left: -15px;

        .inline {
          display: inline-flex !important;
          text-align: left;
          margin-left: 55px;
          margin-top: 10px;
        }

        .step {
          .icon {
            margin-right: 8px;
            margin-top: 5px;
            font-weight: bold;
            padding: 1px 1px;
          }
          .text {
            width: 85%;
            margin-bottom: 15px;
            font-size: 17px;
          }
          #matic {
            padding-bottom: 3px;
          }
        }

      }
    }

    #r {
      display: contents;

      #support {
        margin-top: 20px;
        margin-bottom: 80px;

        .q-item {
          .h {
            font-size: 18px;
            margin-bottom: 15px;
          }

          #phone-nr {
            margin: 0 auto;
            font-size: 18px;
            padding: 6px 18px 5px 10px;
          }

          #phone-descr {
            width: 90%;
            margin: 0 auto;
            margin-bottom: 40px !important;
            margin-top: 15px !important;
          }
        }
      }

    }

    #my-records {
      margin-top: 5px;
      font-size: 18px;
      line-height: 28px;
    }
  }

}