#root-record-container, #root-record-container-mobile {
  margin-bottom: 110px !important;
  border: 1px solid #d7d7d7;
  width: 760px;
  margin: 0 auto;
  padding: 50px 5px;
  border-radius: 30px;

  #animation-container {
    #record-rotation-video {
      clip-path: inset(-1px -1px);
      margin: 0 auto;
      display: block;
      width: 200px;
      margin-bottom: 4px;
    }

    #anim-question-mark {
      display: block;
      margin: 0 auto;
      margin-bottom: 35px;
      color: #c7c7c7;
      font-size: 21px;
      cursor: pointer;

      &:hover {
        color: #8d8d8d;
      }
    }

    #anim-info-text {
      margin-bottom: 45px;

      #descr {
        text-align: center;
        margin: 0 auto;
        color: grey;
        width: 460px;
        line-height: 24px;
        font-size: 17px;
      }

      #order-text {
        color: #404040;
        text-align: center;
        margin-top: 12px !important;
        margin: 0 auto;
        width: 460px;
        line-height: 24px;
        font-size: 17px;

        a {
          font-size: 17px;
        }
      }
    }
  }

  .l1 {
    .record-type {
      background: linear-gradient(50deg, rgb(223 223 223) 0%, rgb(219 215 215) 100%);
      margin-bottom: 5px;
    }

    .change-date-container {
      display: inline-flex;
      height: 31px;
      margin-left: -5px;
      padding: 6px 15px 3px 18px;
      border-left: none !important;
      border: 1px solid #dad8d8;
      border-radius: 5px;

      #label {
        margin-right: 8px;
      }

      #data {
        display: block;
        margin-left: -3px;
        border-radius: 3px;
        margin-top: 1px;

        .cal-icon {
          margin-right: 7px;
          margin-bottom: -2px;
          color: #484848;
        }
      }

    }
  }

  .l2 {
    margin-top: 30px;

    .originator {
      display: grid;
      margin: 0 auto;
      font-size: 15px;
      width: fit-content;

      .data {
        margin-left: 0px !important;
        margin-top: 5px !important;
      }
    }
  }

  .l3 {
    margin-top: 25px;

    #purchase-date {
      width: fit-content;
      display: inline-block;

      .data {
        #purchase-date-icon {
          margin-right: 7px;
          margin-bottom: -3px;
          color: #484848;
        }

        font-family: Arial;
        background-color: #e7e7e7;
        margin-top: 5px;
        padding: 8px 10px;
        padding-bottom: 7px;
        border-radius: 5px;
      }
    }

    #serial-nr {
      margin-top: 28px !important;
      width: fit-content;
      margin: 0 auto;

      .data {
        font-family: monospace;
        background-color: #e7e7e7;
        margin-top: 5px;
        padding: 9px 10px;
        padding-bottom: 10px;
        border-radius: 5px;

        #serial-nr-icon {
          margin-right: 7px;
          margin-bottom: -3px;
          color: #000000;
          font-size: 18px;
        }
      }
    }
  }

  .l4 {
    margin-top: 50px;

    #descr-container {
      width: 700px;
      margin: 0 auto;

      #descr-label {
        font-size: 18px;
        color: #747474;
        font-weight: bold;
      }

      #descr-val {
        margin-top: 10px;

        .red-quotes {
          color: #cd2328;
          font-weight: bold;
        }

        #descr {
          margin-bottom: 11px;
          margin-right: 2px;
        }
      }
    }

    #img-presenter {
      margin-top: 10px;
    }
  }

  #name {
    font-size: 19px;
    font-weight: bold;
    margin-top: 14px;
    margin-bottom: 8px;
  }

  #branch-info-container, #branch-info-container-mobile {
    border-radius: 3px;
    margin-bottom: 80px;
    padding: 25px 11px 14px 17px;
    background: linear-gradient(6deg, rgba(238, 238, 238, 0.46) 0%, rgb(255 249 240 / 18%) 94%);
    border: 1px solid #cfcfcf;

    h1 {
      font-size: 22px;
      margin-bottom: 12px;
    }

    .descr-container {
      margin-top: 30px;
      text-align: left;
      padding-left: 50px;

      .descr {
        margin-bottom: 20px !important;
        margin: 0 auto;
        max-width: 650px;
      }
    }

    .link {
      display: inline-block;
      margin-top: 10px;
      margin-bottom: 10px;
      padding-left: 1px;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: #d34c32;
      }
    }

    .vtr-calc-container {
      margin: 0 auto !important;
      margin-top: 40px !important;
    }

    #button-container {
      margin-bottom: 10px;

      .user-button {
        min-width: 186px;
      }

      #accept-button {
        margin-right: 21px;
        width: 200px;
      }
      #dismiss-button {
        width: 200px;
      }

      #spinner {
        position: absolute;
        width: 20px;
        margin-left: 15px;
        margin-top: 23px;
      }
    }

    #branch-dismissed {
      border: 1px solid #e59400;
      border-radius: 3px;
      width: fit-content;
      margin-top: 18px;
      margin-bottom: 10px;
      padding: 3px 8px;
      background-color: #ffd795;
    }

    #time-to-decide-container {
      margin-top: 7px;
      padding-left: 3px;
      margin-bottom: 7px;

      p {
        font-size: 15px;
        color: #444444;
        margin: 0 auto;
        margin-top: 30px !important;
      }
    }

    #vtr-calc {

    }
  }

  &-mobile {
    margin-top: -40px;
    padding-top: 10px;
    width: 100%;
    margin-left: -4px;

    #animation-container {
      margin-top: 20px;

      #record-rotation-video {
        width: 180px;
        margin-bottom: 15px;
      }

      #anim-question-mark {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 35px;
        font-size: 21px;
        cursor: pointer;

      }

      #anim-info-text {
        margin-bottom: 45px;

        #descr {
          width: 80%;
          line-height: 24px;
          font-size: 17px;
        }

        #order-text {
          margin-top: 21px !important;
          margin: 0 auto;
          width: 90%;
          line-height: 24px;

        }
      }
    }

    .record-content {
      .inline {
        display: inline-flex !important;
      }

      .l1 {
        .change-date-container {
          height: 26px;
          padding-left: 10px;
          margin-left: -5px;

          #data {
            margin-top: 1px;
          }
        }
      }

      .l2 {
        margin-top: 30px;

        .originator {
          margin-top: -2px !important;

          .label {
            font-size: 18px;
          }

          .data {
            font-size: 17px !important;
            padding-top: 9px;
            padding-bottom: 8px !important;
          }
        }
      }

      .l3 {
        margin-top: 20px;
        display: block !important;
      }

      .l4 {
        margin-top: 40px;

        #descr-container {
          width: fit-content;

          #descr-label {
            font-size: 17px !important;
          }

          #descr-val {
            margin-top: 5px;

            #descr {
              font-size: 18px !important;
              margin-left: 2px;
              margin-right: 2px;
            }
          }
        }
      }

      #branch-info-container {
        h1 {
          font-size: 17px !important;
        }

        .descr {
          font-size: 15px !important;
        }

        #button-container {
          display: block;

          #accept-button {
            font-size: 14px !important;
            width: 100%
          }

          #dismiss-button {
            font-size: 14px !important;
            margin-top: 8px;
            width: 100%
          }

          #spinner {
            width: 20px;
            margin-left: 155px;
            margin-top: 15px;
          }
        }

        #time-to-decide-container {
          margin-top: 15px;

          p {
            font-size: 14px !important;
          }
        }
      }


      .record-type {
        font-size: 17px !important;
        padding-bottom: 6px !important;
      }

      .data {
        font-size: 14px !important;
        margin-left: 0px !important;
        display: block;
        width: fit-content;
        margin-top: 4px;
        padding-bottom: 3px !important;
      }

      .change-date {
        .data {
          font-size: 15px !important;
          margin-top: 0px !important;
          margin-left: -2px !important;
          padding-top: 5px;
        }

      }

      #purchase-date {
        display: block;
        margin-top: 10px !important;
        margin: 0 auto;

        .label {
          font-size: 18px !important;
        }

        .data {
          font-size: 17px !important;
          margin-top: 7px;
          display: inline-block;
        }
      }

      #serial-nr {
        display: block;
        margin-top: 28px !important;
        margin: 0 auto;

        .label {
          font-size: 18px !important;
        }

        .data {
          display: inline-block;
          margin-top: 5px;
          font-size: 17px !important;
        }
      }

    }
  }

}