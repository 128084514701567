.hint-container, .hint-container-mobile {
  width: 550px;
  display: grid;
  border: 1px solid #c1c1c1;
  border-radius: 6px;
  padding: 12px 10px 9px;
  margin-top: -10px;

  .hint {
    display: inline-flex;
    margin-bottom: 3px;

    .icon {
      font-size: 17px;
      color: #d34c32;
      margin-right: 5px;
      min-width: 19px;
      margin-top: 6px;
    }

    .text {
      font-size: 17px;
      margin-top: 1px;
      color: #373737;
    }

    .text-active {
      font-size: 16px;
      margin-top: 1px;
      color: #2c2c2c;
    }
  }

  &-mobile {
    width: 94% !important;

    .hint {
      .text {
        font-size: 16px !important;
      }

      .icon {
        margin-top: 4px;
      }
    }
  }
}