#content-container, #content-container-mobile {

  #content-spinner {
    margin-top: -314px;
    margin-bottom: 100px;
    padding-top: 22px;
  }

  #error-container {
    width: 100%;

    .feedback.error {
      width: 70%;
      z-index: 2;
      position: relative;
      margin: 0 auto;
      margin-top: -40px;
      margin-bottom: 40px;

      p {
        font-size: 16px;
        text-align: center;
      }
    }
  }

  #anim-wrapper {
    margin-top: -365px !important;

    &:focus {
      outline:none;
    }

    #light-dots-anim {
      clip-path: inset(3px 1px);
      object-fit: cover;
      position: relative;
      width: 960px;
      top: 282px;
      left: 166px;
      z-index: 0;

      &:focus {
        outline:none;
      }
    }
  }

  #header-container {
    position: relative;
    z-index: 1;
    display: block;
    text-align: center;
    margin-top: -30px;

    #seal-container {
      text-align: center;

      #record-seal {
        margin-top: -15px;
        height: 125px;
        z-index: 2;
        position: relative;
      }
    }

    #product-img-preview {
      width: 300px;
      margin: 0 auto;
      display: block;
      margin-top: 20px;
      border-radius: 23px;
      box-shadow: 1px -1px 5px 3px #dbdbdb;
    }

    #product-name {
      display: inline-block;
      margin-top: 0px !important;

      h1 {
        letter-spacing: 0px !important;
      }
    }

    #copy-to-clipboard {
      display: inline-flex;
      height: 5px;
      margin-top: 8px;
      background: linear-gradient(123deg, rgb(212 212 212) 0%, rgb(226 226 226) 50%);
      padding: 8px 12px 24px;
      border-radius: 6px;
      cursor: pointer;

      #copy-icon {
        font-size: 18px;
        margin-right: 3px;
        cursor: pointer;
        margin-top: 2px;
        color: #565656;
      }

      #check-icon {
        margin-top: 3px;
        font-size: 15px;
        margin-left: 7px;
        color: #303030;
      }

      p {
        color: #565656;
        font-size: 15px;
        margin-top: -3px;
        margin-left: 2px;
        cursor: pointer;
      }

      &:hover {
        background-color: #dfdfdf;

        #copy-icon {
          color: #cd2328;
        }
        p {
          color: #cd2328;
        }

      }
    }
  }

  &-mobile {
    margin-top: 38px !important;

    #content-spinner {
      margin-top: -45px;
    }

    #error-container {
      .feedback.error {
        width: 90%;
        min-width: 0;
      }
    }

    #anim-wrapper {
      margin-top: 0px !important;

      #light-dots-anim {
        position: absolute;;
        left: -2px;
        width: 100%;
        top: 9%;
      }
    }

    #header-container {
      z-index: 0;
      margin-top: -15px;
      display: block;

      #seal-container {
        #record-seal {
          height: 100px;
        }
      }

      #product-name {
        display: block !important;
        margin-top: 20px !important;

        #copy-to-clipboard {
          margin-top: 3px !important;
          height: 6px;
        }
      }

      h1 {
        margin-top: 8px !important;
        margin: 0 auto;
        margin-bottom: 15px;
        text-align: center;
        font-size: 27px !important;
      }

      #copy-to-clipboard {
        margin-left: 0px !important;
        margin-top: 2px !important;

        p {
          font-size: 14px !important;
        }
      }
    }
  }
}

.underlineOnHover {
  &:hover {
    text-decoration: underline;
  }
}

#record-history-container {
  margin-bottom: 50px;

  .summary-boxes {
    margin-top: 30px;
  }

  #claim-badge-container {
    margin-top: 50px;
    margin-bottom: 23px;
    background: linear-gradient(8deg, rgb(255 255 255 / 34%) 0%, rgb(245 245 245 / 54%) 100%);
    padding-top: 22px;
    padding-bottom: 25px;
    border-radius: 20px;
    border: 1px solid #cfcfcf;
    text-align: center;
    z-index: 1;
    position: relative;

    #claim-button {
      font-size: 17px;
      width: 215px;
      margin-top: 20px;
      margin-bottom: 3px;
    }
  }

  #display-options, #display-options-mobile {
    display: inline-flex;
    width: 100%;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 60px;

    #time-divider-checkbox {
      background: linear-gradient(314deg, rgb(211 211 211 / 34%) 0%, rgb(245 245 245) 100%);
      padding-left: 9px;
      padding-top: 7px;
      padding-right: 15px;
    }

    #update-filter-dropdown {
      display: inline-flex;
      margin-top: 6px;
      margin-bottom: -6px;
      margin-left: 40px;

      .input-label {
        margin-top: 6px !important;
        margin-right: 4px;
        color: #393939;
      }

      .dropdown {
        width: 160px !important;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 8px;
        height: 40px;
        padding-top: 9px;
      }
    }

    &-mobile {
      display: block;
      text-align: center;
      margin-bottom: 150px;

      #time-divider-checkbox {
        width: 94%;

        .label {
          font-size: 17px !important;
          margin-top: 2px;
        }
      }

      #update-filter-dropdown {
        margin-left: -2px;
        margin-top: 20px;

        .input-label {
          margin-top: 8px !important;
        }

        .dropdown {
          padding-top: 7px;
        }
      }
    }
  }

  .time-divider-container {
    text-align: center;
    margin-top: 70px;
    margin-bottom: 210px;

    .time-divider {
      height: 65px;
      margin-top: 20px;

      .divider-top {
        width: 15px;
      }

      .divider-bottom {
        width: 15px;
        transform: rotate(180deg);
      }

      .info-since {
        color: #8d8d8d;
        margin-bottom: 15px;
        margin-top: 15px;
      }
    }
  }

  #history-items {
    margin-top: 50px;
  }

  .record-content {
    margin-left: 2px;
    text-align: center;

    .record-type {
      background-color: #b7b7b7;
      padding: 7px 9px;
      padding-right: 15px;
      border-radius: 5px;
      width: fit-content;
      margin-bottom: 5px;

      .type-icon {
        font-size: 17px;
        margin-bottom: -2px;
        margin-right: 6px;
      }
    }

    .record-creation-date {
      margin-bottom: 5px;

      .data {
        font-family: monospace;
        background-color: #e1e1e1;
        padding: 5px 13px 5px 12px;
        border-radius: 5px;
        width: fit-content;
        margin: 0 auto !important;
        margin-top: 5px !important;

        .cal-icon {
          margin-right: 10px;
          margin-bottom: -2px;
          color: #484848;
          font-size: 17px;
        }
      }
    }

    .originator {
      margin-top: 12px;

      .data {
        background-color: #e1e1e1;
        margin-left: 36px;
        padding: 8px 12px;
        border-radius: 5px;
      }
    }
  }
}

#branch-link {
  margin-top: 100px;
  text-align: center;

  p {
    font-size: 15px;
    cursor: default;
    color: grey;
    max-width: inherit;
  }

  #start-branch-link {
    text-decoration: underline;

    &:hover {
      cursor: pointer;
      color: black
    }
  }
}

#transfer-confirmation-form {
  border: 1px solid #c7c7c7;
  padding: 20px 23px;
  border-radius: 3px;
  margin-top: 25px;
  margin-bottom: 40px;
  max-width: 100% !important;

  h1 {
    font-size: 18px;
  }

  #descr {
    font-size: 15px;
    line-height: 19px;

    #new-role-ul{
      li {
        margin-bottom: 3px;
      }
    }
  }

  #confirm-transfer-button { }

}


#privacy-notice, #privacy-notice-mobile {
  margin-top: 20px;
  border: 1px solid lightgrey;
  border-radius: 3px;
  padding: 19px 20px 31px;
  background-color: #fbfbfb;
  text-align: center;

  h1 {
    font-size: 23px;
    width: auto;
  }
  #descr {
    margin-top: 20px;
  }

  &-mobile {
    padding-top: 10px;
    h1 {
      font-size: 19px !important;
    }
    #descr {
      font-size: 16px !important;
    }
  }
}

#privacy-info-owner, #privacy-info-owner-mobile {
  font-size: 17px;
  border: 1px solid #e5d0e7;
  background: linear-gradient(356deg, rgba(252, 252, 252, 0.28) 0%, rgb(253, 233, 255) 75%);
  padding: 10px 10px;
  border-radius: 3px;
  width: 1168px;
  text-align: center;

  &-mobile {
    width: 95%;
    font-size: 14px;
    margin-top: 20px;
    text-align: center;
  }
}

#extension-buttons, #extension-buttons-mobile {
  position: relative;
  z-index: 1;
  margin-top: 25px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;

  .ext-button {
    height: 45px;
    padding-right: 14px;
    padding-left: 13px;
    font-size: 17px;

    .text-icon {
      color: #ff684b;
    }
  }

  #create-extension-button {
    margin-right: 18px;
  }

  #transfer-ownership-button {
    margin-right: 18px;
  }

  #private-mode-button {
    margin-right: 18px;
  }

  #dispute-record-button {
    margin-right: 15px;
  }

  &-mobile {
    z-index: 0;
    margin-top: 15px;
    display: block;

    .ext-button {
      font-size: 16px;
      margin-bottom: -3px;
    }

    #create-extension-button,
    #transfer-ownership-button,
    #private-mode-button,
    #dispute-record-button,
    #report-loss-button{
      width: 99% !important;
    }

    #cancel-extension-button {
      margin: 0 auto;
      display: block;
      margin-top: 30px;
      margin-bottom: 60px;
    }

  }

}

#pending-info-container {
  margin-top: 60px;
  background: linear-gradient(187deg, rgb(253 236 148) 0%, rgb(255 232 113) 100%);
  border-radius: 8px;
  text-align: center;
  padding: 11px 0px;
  z-index: 1;
  position: relative;

  #pending-info-text {
    #icon {
      margin-right: 10px;
      font-size: 23px;
      margin-bottom: -4px;
      color: #484216;
    }
  }
}

#pending-live-container {
  margin-top: 40px;
  background: linear-gradient(187deg, rgb(79 237 73) 0%, rgb(137 233 134) 100%);
  border-radius: 8px;
  text-align: center;
  padding: 11px 0px;
  z-index: 1;
  position: relative;

  #pending-live-text {

    #link {
      text-decoration: underline;
      cursor: pointer;
    }

    #icon {
      margin-right: 10px;
      font-size: 23px;
      margin-bottom: -5px;
      color: #1d5c03;
    }
  }
}