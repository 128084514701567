.dropdown-container {
  margin-top: 15px;
  margin-bottom: 15px;

  .dropdown {
    font-size: 16px;
    width: 222px;
    margin-top: 8px;
    margin-bottom: 6px;
    border: 1px solid #ababab !important;
    padding: 9px 11px 8px 10px;
    background: linear-gradient(155deg, rgb(245 245 245) 0%, rgb(239, 246, 247) 100%);
    outline: none;
    border-radius: 6px;

    &:disabled {
      cursor: not-allowed;
    }
  }
}