#reliable-information-container, #reliable-information-container-mobile {
  padding-top: 90px;
  padding-bottom: 90px;

  .hl {
    color: #d34c32;
  }

  #content {
    width: 1300px;
    margin: 0 auto;
    text-align: center;

    #headline {
      font-family: "BS", "Arial", sans-serif;
      transform: scaleY(0.9);
      font-size: 40px;
      letter-spacing: -1px;
    }

    #descr {
      margin-top: 40px !important;
      font-size: 19px;
      line-height: 28px;
      width: 800px;
      margin: 0 auto;
    }

    #explainer-diagram {
      width: 1210px;
      margin-top: 50px;
    }

    #additional-explainers {
      margin-top: 60px;

      .diagram-explainer {
        cursor: pointer;
        width: 580px;
        border: 1px solid #c7c7c7;
        border-radius: 10px;
        padding-top: 10px;
        padding-bottom: 8px;
        margin-bottom: 15px !important;
        margin: 0 auto;
        text-align: left;
        padding-left: 20px;

        .inline {
          .arrow {
            cursor: pointer;
            color: #cd2328;
            font-size: 20px;
            margin-top: 3px;
          }

          .title {
            cursor: pointer;
            margin-left: 8px;
            font-size: 18px;
            color: #2e2e2e;
            font-weight: bold;
          }

        }

        .expanded-text {
          cursor: pointer;
          margin-top: 12px !important;
          font-size: 18px;
          line-height: 26px;
          width: 90%;
          margin: 0 auto;
          padding-bottom: 7px;
          margin-left: 28px;

          a {
            font-size: 17px;
          }
        }

        &:hover {
          background: linear-gradient(322deg, rgb(253 253 253) 0%, rgb(245 245 245) 30%);
        }
      }
    }

  }

  &-mobile {
    margin-top: 10px;
    padding-top: 50px;
    padding-bottom: 60px;

    #content {
      width: 98%;
      margin: 0 auto;
      text-align: center;

      #headline {
        font-size: 33px;
        letter-spacing: -1px;
        width: 80%;
        margin: 0 auto;
        line-height: 35px;
      }

      #descr {
        margin-top: 30px !important;
        font-size: 18px;
        line-height: 26px;
        width: 96%;
        margin: 0 auto;
      }

      #explainer-diagram-container {

        #explainer-diagram {
          width: 100%;
          margin-top: 40px;
        }

        #explainer-diagram-hints {
          #explainer-hint, #explainer-hint-low {
            z-index: 2;
            width: 96%;
            position: absolute;
            margin-top: -125% !important;
            margin: 0 auto;
            margin-left: 2px !important;
            background: linear-gradient(305deg, rgb(227, 227, 227) 5%, rgb(234, 234, 234) 33%, rgb(246, 246, 246) 90%);
            border: 1px solid grey;
            border-radius: 9px;
            padding-top: 20px;
            padding-bottom: 15px;
            box-shadow: 1px 1px 9px 1px #b7b7b7;

            &-low {
              margin-top: -100% !important;
            }

            #title {
              font-weight: bold;
              color: #d94521;
            }

            #hint-descr {
              width: 95%;
              margin-top: 12px !important;
              margin: 0 auto;
              line-height: 23px;
            }

            #close-icon {
              font-size: 28px;
              margin-top: 20px;
              color: #8f8f8f;
            }
          }

          .hint-icon {
            font-size: 26px;
            color: #686868;
          }

          #retail-stores-icon {
            position: absolute;
            margin-top: -116%;
            margin-left: -30%;
          }

          #repair-shops-icon {
            position: absolute;
            margin-top: -116%;
            margin-left: 21%;
          }

          #factories-icon {
            position: absolute;
            margin-top: -95%;
            margin-left: -47%;
          }

          #owners-icon {
            position: absolute;
            margin-top: -95%;
            margin-left: 39%;
          }

          #moderation-icon {
            position: absolute;
            margin-top: -46%;
            margin-left: 6%;
          }

          #your-order-icon {
            position: absolute;
            margin-top: -30%;
            margin-left: -14%;
          }

          #your-vr-icon {
            position: absolute;
            margin-top: -9%;
            margin-left: 6%;
          }

        }
      }

      #additional-explainers {

        .diagram-explainer {
          width: 90%;

          .title {
            line-height: 23px;
          }
        }
      }

    }
  }

}