.text-input-container {
    display: grid;
    margin-top: 15px;
    margin-bottom: 15px;

    .label-container {
        margin-top: 8px;
        display: inline-flex;
    }

    .char-limit {
        margin-top: 6px;
        font-size: 16px;
        margin-left: 10px;
        color: #9c5f02;
        margin-bottom: 0px;
    }

    .input-container {
        display: flex;

        #reset-button {
            min-width: 10px !important;
            padding: 6px 4px !important;
            padding-left: 11px !important;
            width: 44px;
            height: 43px;
            margin-top: 8px;
            margin-left: -3px;
            border: none;
            border-top: 1px solid #949494;
            border-right: 1px solid #949494;
            border-bottom: 1px solid #949494;
            box-shadow: none;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;

            &:hover {
                background-color: #ad3d2c !important;
            }
        }

        #submit-button {
            min-width: 10px !important;
            padding: 6px 4px !important;
            width: 42px;
            background-color: #c5c5c5;
            height: 42px;
            margin-top: 8px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            margin-left: -1px;
            border: none;
            border-top: 1px solid #949494;
            border-right: 1px solid #949494;
            border-bottom: 1px solid #949494;
            box-shadow: none;
            background: none;

            .submit-icon {
                font-size: 17px;
                padding-top: 4px;
                color: #323232;
            }

            &:hover {
                background-color: #b0b0b0 !important;
                color: white !important;
            }
        }
    }
}


.text-input {
    font-size: 18px;
    padding-top: 12px;
    padding-bottom: 10px;
    padding-left: 12px;
    margin-top: 8px;
    margin-bottom: 6px;
    border: 1px solid #949494 !important;
    background: linear-gradient(155deg, rgb(245 245 245) 0%, rgb(239, 246, 247) 100%);
    outline: none;
    border-radius: 10px;

    &:hover {
        border: 1px solid #b5240e !important;
        background-color: #e8e8e8de;
    }

    &:focus {
        background-color: #e8e8e8de;
        border: 1px solid #d34c32 !important;
        background: linear-gradient(155deg, rgb(239, 239, 239) 0%, rgb(243 243 243) 100%);
    }

}