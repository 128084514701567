.vtr-calc-container, .vtr-calc-container-mobile {
  background: linear-gradient(147deg, rgb(255 237 233) 0%, rgb(253 248 248) 25%, rgb(241, 229, 225) 75%);
  padding: 6px 20px;
  padding-bottom: 20px;
  margin-top: 22px;
  border-radius: 5px;
  border: 1px solid #e8d0c5;
  width: 380px;

  &-mobile {
    min-width: 92%;
    width: auto;
    padding: 8px 10px;

    .h1-mobile {
      margin-top: 7px !important;
      font-size: 21px !important;
      width: 100%;
    }

    #gas-estimate {
      font-size: 13px !important;
    }
  }

  h1 {
    font-size: 24px !important;
    font-weight: normal;
    margin-top: 14px;
    margin-bottom: 15px;
  }

  .descr-calc, .descr-calc-mobile {
    font-size: 17px;
    margin-bottom: 30px;

    &-mobile {
      font-size: 17px;
      padding-left: 4px;
      text-align: center;
      line-height: 22px;
      margin-top: 4px;
    }
  }

  .row, .row-mobile {
    display: inline-flex;
    width: 98%;
    background-color: rgb(243 224 221);
    padding: 3px 1px 3px 11px;
    margin-left: -2px;
    margin-bottom: 8px;

    &-mobile {

    }

    .l, .l-mobile {
      width: 75%;
      font-size: 16px;
      padding-left: 2px;

      &-mobile {
        font-size: 16px;
        width: 71%;
      }
    }
    .r, .r-mobile {
      width: 20%;
      text-align: right;
      font-size: 16px;

      &-mobile {
        font-size: 16px;
        text-align: right;
        position: sticky;
        width: 70px;
      }
    }
  }

  .after, .after-mobile {
    margin-top: 5px;
    background-color: #ffffff00;
    border-top: 1px solid grey;
    padding-top: 10px;
  }

  .reward {
    color: green;
  }

  .cost {
    color: red;
  }

  #spinner-container {
    margin-top: 20px;
    #spinner {
      width: 25px;
    }
  }

  #gas-estimate-container {
    display: block;
    margin-top: 5px !important;
    margin: 0 auto;
    text-align: center;

    #gas-estimate {
      padding-left: 9px;
      font-size: 14px;
      color: #505050;

      #data {}

    }

    #tooltipContainer {
      margin-top: 4px;
      margin-left: 10px;
    }
  }

  #enough-balance {
    margin-top: 5px;
    padding: 2px 1px 3px 8px;

    p {
      font-size: 14px;
      color: red;
    }
  }

}