#privacy-policy, #privacy-policy-mobile {
  padding-bottom: 100px !important;

  #intro-descr {
    margin-bottom: 30px;
  }

  .paragraph {
    width: 960px;
  }

  .paragraph-section-h {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 5px;
    font-weight: bold;
  }

  h2 {
    margin-top: 40px;
    color: #c7462d;
  }

  h3 {
    margin-top: 30px;
  }

  &-mobile {
    .paragraph {
      width: 100%;
    }
  }
}