.content-container, .content-container-mobile {
  padding-bottom: 50px;

  .p-content {
    width: 740px;
  }

  h2 {
    font-weight: normal;
  }

  #team-container {
    margin-top: -15px;
    width: 870px;

    #portraits {
      .portrait {
        border-radius: 7px;
        width: 300px;
      }
      .person-descr {
        font-size: 17px;
        margin-top: 8px;
        width: 410px;
        line-height: 23px;

        .name {
          font-weight: bold;
          margin-right: 5px;
        }
      }
    }
  }

  #why-container {
    margin-top: 30px;
    width: 870px;

    .p-content {
      width: 80%;
    }

  }

  #blog-container {
    margin-top: 60px;

    #p2 {
      margin-top: 20px;
    }

    #go-to-button {
      font-size: 17px;
      width: 220px;
      height: 42px;
      padding-top: 4px;
      margin-top: 20px;
      padding-bottom: 2px;
    }
  }

  #contact-container {
    margin-top: 20px;
    padding: 20px 27px;
    padding-bottom: 25px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    width: 360px;
    margin-left: 10px;

    #h {
      font-size: 19px;
      margin-bottom: 20px;
    }

    .contact-method {
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 2px;
    }

    #phone-nr {
      margin-top: 8px;
      font-size: 18px;
      padding: 8px 15px 6px 10px;
      background-color: #e9e9e9;
      border-radius: 8px;
      width: fit-content;

      #phone-icon {
        color: #7c7c7c;
        margin-bottom: -2px;
        margin-right: 8px;
      }
    }

    #phone-info {
      font-size: 17px;
      margin-top: 8px;
      margin-bottom: 30px;
      line-height: 27px;
    }

    #address {
      font-size: 17px;
      line-height: 27px;
    }
  }

  #social-container {
    margin-top: 80px;

    #ig-links {
      margin-top: 20px;
      display: grid;

      .ig-link {
        display: inline-flex;
        margin-bottom: 20px;
        cursor: pointer;

        .logo {
          height: 35px;
          margin-top: 10px;
          margin-right: 17px;
        }

        .text {
          .profile {
            font-weight: bold;
            font-size: 17px;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
          .descr {
            font-size: 17px;
            margin-top: -2px;
            width: 480px;
            line-height: 23px;
            cursor: pointer;
          }
        }
      }

    }
  }

  &-mobile {
    #h1-about {
      margin-top: 10px;
    }

    .inline {
      display: block;
    }

    .p-content {
      width: 100% !important;
      font-size: 18px;
      line-height: 29px;
    }

    #team-container {
      margin-top: -10px;
      width: 100%;

      #portraits {

        .portrait {
          width: 100%;
        }
      }

      .person-descr {
        width: 100% !important;
      }
    }

    #why-container {
      margin-top: 50px;
      width: 100%;
      h2 {
        margin-top: 10px;
      }
    }

    #contact-container {
      margin-top: 70px;
      margin-left: 0px;
      width: 87%;
      padding-left: 22px;
    }

    #blog-container {
      margin-top: 60px;

      h2 {
        margin-top: 10px;
      }

      #go-to-button {
        margin-top: 30px;
        height: 42px !important;
      }
    }

    #social-container {
      margin-top: 60px;
      margin-bottom: 60px;

      h2 {
        margin-top: 10px;
      }

      #ig-links {
        margin-top: 20px;
        .ig-link {
          .logo {
            height: 27px;
            margin-top: 7px;
          }

          .text {
            .descr {
              font-size: 17px;
              width: 100%;
              line-height: 23px;
            }
          }
        }

      }
    }

  }
}