.infobox-kpis, .infobox-kpis-mobile {
  padding-top: 70px;
  padding-bottom: 100px;

  #content {
    margin: 0 auto;
    text-align: center;

    #headline {
      font-family: "BS", "Arial", sans-serif;
      transform: scaleY(0.9);
      letter-spacing: -1px;
      font-size: 30px;
    }

    .kpis {
      display: inline-flex;
      text-align: center;
      margin-top: 70px;
      margin-bottom: 15px;


      #records-created {
        border-right: 1px solid #adadad;
        padding-right: 30px;
      }

      #vtr-circulation {
        padding-left: 30px;
      }

      .kpi-container {
        width: 190px;

        .value {
          font-size: 35px;
          color: #b33631;
          font-weight: bold;
        }

        .name {
          margin-top: 20px;
          font-size: 18px;
        }

      }
    }

  }

  &-mobile {
    margin-top: 10px;

    #h2 {
      font-size: 25px !important;
    }

    .descr {
      font-size: 20px !important;
    }

    .bpbg {
      width: 360px;
      margin-bottom: -283px;
      margin-left: -15px;
    }

    #speedometer {
      width: 230px !important;
      margin-top: 25px;
      margin-bottom: 10px;
    }

    .kpis {
      margin-top: 50px !important;
      display: block !important;
      width: 100% !important;

      .kpi-container {
        width: 100% !important;
        margin-bottom: 20px;

        .value {
          font-size: 25px;
        }
      }

      #vtr-circulation {
        padding-left: 0px !important;
        margin-top: 45px;
      }

    }
  }

}